<template>
    <div
        class="panel panel-default card-input my-3 font-weight-bold"
        style="opacity: 1; cursor: auto;">
        <div class="panel-heading py-2 px-4">
            <div class="row align-items-center">
                <span class="col-6">{{ product.product.product.name }}</span>
                <div method="post" class="col-6">
                    <button @click="$emit('delete')" type="submit" name="delete"
                        class="btn float-right">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-x" width="30px" height="30px"
                            viewBox="0 0 24 24" stroke-width="1.5" stroke="red" fill="none"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div class="panel-body py-2 px-4">
            <span>{{ $t("products.type") }}: {{ product.product.product.type }}</span><br>
            <span>{{ $t("products.length") }}: {{ product.product.product.length }} cm</span><br>
            <span class="d-inline-flex align-items-center py-1">{{ $t("products.amount") }}:&nbsp;<input type="number" class="form-control w-50 font-weight-bold"
                    id="item_price" name="item_price" min="1" v-model="product.amount">&nbsp;{{
                product.product.product.measurement_unit }}</span><br>
            <div>
                <!-- Display filtered prices for the selected currency -->
            <span class="d-inline-flex align-items-center py-1">
                {{ $t("products.vatPrice") }}:&nbsp;
                <input type="number" class="form-control w-50 font-weight-bold"
                    id="item_price" name="item_price"
                    min="0"
                    @focus="saveOld()" @blur="restoreOld()" v-model="amount">&nbsp;{{
                        product.product.currency }}
            </span><br>
                <!-- Display discounts for each product in the order -->
                <!-- <span
                    class="d-inline-flex align-items-center py-1">
                    {{ $t("products.sale") }}:&nbsp;
                    <input type="number" class="form-control w-50 font-weight-bold"
                        id="item_discount" name="item_discount"
                        v-model="discount" min="0" max="100">&nbsp;%
                </span><br> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            amount: 0,
            amountOld: 0,
            discount: 0
        }
    },
    created: function () {
        this.amount = this.product.product.amount;
        // this.discount = 1 - this.product.product.discount;
    },
    watch: {
        amount: function () {
            this.product.product.amount = this.amount;
        },
        // discount: function(){
        //     this.product.product.discount = this.discount + 1;
        //     for (var i = 0; i < this.product.product.product.pricesWithVat.length; i++){

        //     }
        // }
    },
    methods: {
        saveOld: function () {
            this.amountOld = this.amount;
            this.amount = null;
        },
        restoreOld: function () {
            if (this.amount == null || this.amount == "" || this.amount == undefined) {
                this.amount = this.amountOld;
            }
            this.amountOld = null;
        }
    }
}
</script>