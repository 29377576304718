<template>
    <div class="p-2 p-lg-5">
        <div class="row">
            <div class="col-6 ps-4 ms-2">
                <h2>{{ $t("titles.customers") }}</h2>
            </div>
            <div v-if="$store.getters.hasPerm('add_customer')" class="col-auto ms-auto">
                <router-link :to="{ name: 'AddCustomer' }" class="button-top">{{ $t("customer.add")
                }}</router-link>

            </div>
        </div>
        <Table endpoint="/customers" ref="table" class="mt-3" :filters="filters">
            <template v-slot:filters>
                <div class="row pt-3">
                    <div class="col-3 col-lg-2">{{ $t("customer.internId") }}</div>
                    <div class="col-2">{{ $t("customer.firstName") }}</div>
                    <div class="col-2">{{ $t("customer.email") }}</div>
                    <div class="col-2">{{ $t("customer.phone") }}</div>
                    <div class="col-3">{{ $t("customer.address") }}</div>
                </div>
                <div class="row py-3 item-line">
                    <div class="col-3 col-lg-2">
                        <input type="text" class="form-control" placeholder="AA23010001" v-model="filters.internId">
                    </div>
                    <div class="col-2">
                        <input type="text" class="form-control" placeholder="Firma s.r.o." v-model="filters.fullName">
                    </div>
                    <div class="col-2">
                        <input type="text" class="form-control" placeholder="jmeno@firma.cz" v-model="filters.email">
                    </div>
                    <div class="col-2">
                        <input type="text" class="form-control" placeholder="736 109 281" v-model="filters.phone">
                    </div>
                    <div class="col-3">
                        <input type="text" class="form-control" placeholder="Ulice 11, 110 00 Město" v-model="filters.address">
                    </div>
                    <div class="col-8 col-lg-2 mt-4">
                        <button class="btn btn-primary btn-color-white" @click="clearFilters">{{ $t("utils.clearFilters") }}</button>
                    </div>
                </div>
            </template>
            <template v-slot:header >
                <div class="col-3 col-lg-2">{{ $t("customer.id") }}</div>
                <div class="col-2">{{ $t("customer.fullName") }}</div>
                <div class="col-2 text-break">{{ $t("customer.email") }}</div>
                <div class="col-2 text-break phone-number">{{ $t("customer.phone") }}</div>
                <div class="col-3 col-lg-2">{{ $t("customer.address") }}</div>
                <div class="col-3 col-lg-1 mt-4 mt-lg-0">{{ $t("customer.action") }}</div>
            </template>
            <template #row="line">
                <div class="col-3 col-lg-2">{{ line.item.intern_id }}</div>
                <div class="col-2">{{ line.item.name }}</div>
                <div class="col-2 text-break">{{ line.item.email }}</div>
                <div class="col-2 text-break phone-number">{{ line.item.whole_phone }}</div>
                <div class="col-3 col-lg-2">{{ line.item.address.full_address }}</div>
                <router-link :to="'/customer/' + line.item.id" class="col-3 col-lg-1 mt-4 mt-lg-0">{{ $t("utils.detail")
                }}</router-link>
                <router-link :to="'/customer/' + line.item.id + '/edit'" class="col-3 col-lg-1 mt-4 mt-lg-0">{{ $t("utils.edit")
                }}</router-link>
            </template>
        </Table>
    </div>
</template>

<script>
import Table from "@/components/Table.vue";

export default {
    components: {
        Table
    },
    data() {
        return {
            filters: {
                internId: null,
                fullName: null,
                email: null,
                phone: null,
                address: null
            }
        }
    },
    created: function () {
        if (this.$store.getters.customerFilters) {
            this.filters = this.$store.getters.customerFilters;
        }
    },
    watch: {
        filters: {
            handler: function () {
                console.log(this.filters);
                this.$store.commit("updateCustomerFilters", this.filters);
            },
            deep: true
        },
    },
    methods: {
        clearFilters: function () {
            this.filters = {
                internId: null,
                fullName: null,
                email: null,
                phone: null,
                address: null
            }
        }
    }
}
</script>
