<template>
    <div v-if="Object.keys(order).length !== 0" class="row p-5" id="order">
        <div class="col-12 px-md-5">
            <div class="row">
                <div class="col-12 col-md-7">
                    <h2>{{ $t("orders.order") }} - {{ orderTitle }}</h2>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12 col-md-7 pr-5">
                    <div class="mt-4">
                        <label for="driver" class="mt-2">Odveze řidič</label><br>
                        <select class="form-control" v-model="order.driver_id" size="1" required>
                            <option value="oo">Osobní odběr</option>
                            <option :value="driver.id" v-for="driver in drivers">
                                {{ driver.last_name }} {{ driver.first_name }} ({{driver.username }})</option>
                        </select>
                    </div>
                    <div class="mt-4">
                        <label for="delivery_date" class="mt-2">Očekávané datum a čas dodání</label><br>
                        <input type="datetime-local" class="form-control" v-model="order.expected_delivery_datetime">
                    </div>
                    <div class="mt-4 d-inline-flex align-items-center">
                        <label for="delivery_duration" class="mt-2">Očekávaná doba strávená u klienta</label>
                        <input type="number" class="form-control w-75 float-left"  min="0" v-model="order.expected_delivery_duration">
                        <span>&nbsp;minut</span>
                    </div>
                    <div class="mt-4">
                        <label for="note" class="mt-2">Poznámka k objednávce</label><br>
                        <textarea type="text" class="form-control" v-model="order.note" placeholder="Poznámka k objednávce">{{ order.note }}</textarea>
                    </div>
                    <div class="mt-4">
                        <label for="ad_type" class="mt-2">Typ reklamy</label><br>
                        <select class="form-control" v-model="order.ad_type" required>
                            <option :value="-1">Žádný</option>
                            <option v-for="ad_type in adtypes" :value="ad_type.id">{{ ad_type.name }}</option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <button class="mt-4 btn btn-primary w-100 form-submit" @click="toDetail()">Uložit a jít na detail</button>
                        </div>
                        <div class="col-4">
                            <button class="mt-4 btn btn-primary w-100 form-submit" @click="toList()">Uložit a jít na výpis</button>
                        </div>
                        <div class="col-4">
                            <button class="mt-4 btn btn-primary w-100 form-submit" @click="toNext()">Uložit a vytvořit další objednávku</button>
                        </div>
                    </div>

                    <CalendarSettings @calendarFromChanged="(value)=>{calendarFrom=value}"
                    @calendarToChanged="(value)=>{calendarTo=value}"
                    @showIdsChanged="(value)=>{showIds=value}" @showWithoutTravelChanged="(value)=>{showTravel=!value}" />
                </div>
                <div class="col-12 col-md-5">
                    <CalendarDayColumns
                        ref="calendar"
                        :selectedDate="selectedDate"
                        :selectedWarehouses="[order.warehouse.id]"
                        :showTravelTime="showTravel"
                        :calendarFrom="calendarFrom"
                        :calendarTo="calendarTo"
                        :showIds="showIds"
                        :showOneDay="true"
                        :hideHeader="true"
                        :showCobined="true"
                        :selectedOrderOutside="order"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CalendarDayColumns from "@/components/CalendarDayColumns.vue";
import CalendarSettings from "@/components/CalendarSettings.vue";

export default {
    components: {
        CalendarDayColumns,
        CalendarSettings
    },
    data() {
        return {
            order: {},
            drivers: [],
            adtypes: [],
            showTravel: true,
            showIds: false,
            calendarTo: 18,
            calendarFrom: 6,
            selectedDate: new Date(),
            selectedDateTimeout: null,
            selectedInputDate: null
        }
    },
    created: function () {
        this.loadData();
        this.calculateSelectedDate();
    },
    computed: {
        orderTitle: function () {
            if (this.order) {
                if (this.order.intern_id) {
                    return this.order.intern_id;
                }
                return this.order.id

            }
            return this.$route.params.id;
        },
    },
    watch: {
        'order.expected_delivery_datetime': function () {
            this.calculateSelectedDate()
        }
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get('/order/' + this.$route.params.id).then(function (response) {
                this.order = response.data.item;
                this.order.warehouse.color = "#ff00ff"
                this.order.warehouse.text_color = "#000000"
                if (this.order.driver) {
                    this.order.driver_id = this.order.driver.id;
                }
            }.bind(this));
            this.$store.getters.api.get('/order/' + this.$route.params.id + "/drivers").then(function (response) {
                this.drivers = response.data.items;
            }.bind(this));
            this.$store.getters.api.get('/adtypes').then(function (response) {
                this.adtypes = response.data.items;
            }.bind(this));
        },
        toDetail: function () {
            this.sendForm(() => { this.$router.push('/order/' + this.order.id) });
        },
        toList: function () {
            this.sendForm(() => { this.$router.push('/orders') });
        },
        toNext: function () {
            this.sendForm(this.createOrder);
        },
        createOrder: function () {
            this.$store.getters.api.get("/order/add").then((response) => {
                this.$router.push({name: "UpdateOrderCustomer", params: {id: response.data.id}})
            })
        },
        sendForm: function (func) {
            if (this.$refs.calendar.overlapingOrders()) {
                if (!confirm("Chcete opravdu uložit objednávku ve stejný čas?")) {
                    return false;
                };
            }
            this.$store.getters.api.post("/order/" + this.$route.params.id + "/intern", this.order).then((response) => {
                func();
            });
        },
        calculateSelectedDate: function () {
            var date = new Date(Date.parse(this.order.expected_delivery_datetime));
            if (!isNaN(date)) {
                if (this.selectedDateTimeout) {
                    clearTimeout(this.selectedDateTimeout);
                }
                this.selectedDateTimeout = setTimeout(function () {
                    this.selectedDate = date;
                }.bind(this), 300);
            }
        }
    },
}
</script>
<style scoped lang="scss"></style>