<template>
    <div class="row p-2 p-lg-5" id="overviews">
        <div class="col-12 px-2 px-lg-5">
            <CampaingsOverviews :warehouses="warehouses"/>
            <WarehousesOver :warehouses="warehouses"/>
            <ProductionOver class="my-4" :warehouses="warehouses"/>
        </div>
    </div>
</template>
<script>
import WarehousesOver from "@/components/overviews/WarehousesOver.vue";
import ProductionOver from "@/components/overviews/ProductionOver.vue";
import CampaingsOverviews from "@/components/overviews/CampaingsOverviews.vue";

export default {
    components: {
        WarehousesOver,
        ProductionOver,
        CampaingsOverviews
    },
    data: function(){
        return {
            warehouses: []
        }
    },
    created: function () {
        this.getWarehouses();
    },
    methods: {
        getWarehouses: function () {
            this.$store.getters.api.get("/warehouses").then((response) => {
                this.warehouses = response.data.items;
            });
        }
    }
}
</script>
<style lang="">

</style>