<template>
    <div class="p-5">
        <h2>{{ $t("products.add.adding") }}</h2>
        <form @submit.prevent="handleSubmit" method="post" action="" class="row w-50" autocomplete="off">
            <div class="col-12">
                <h3>{{ $t("products.description") }}</h3>
            </div>
            <div class="col-12">
                <label for="name" class="mt-2">{{ $t("products.name") }}<span>*</span></label>
            </div>
            <div class="col-12">
                <input v-model="formData.name" type="text" class="form-control" id="name" name="name" placeholder="Název produktu" required>
            </div>
            <div class="col-12">
                <label for="shorten_name" class="mt-2">{{ $t("products.shortcut") }}<span>*</span></label>
            </div>
            <div class="col-12">
                <input v-model="formData.shorten_name" type="text" class="form-control" id="shorten_name" name="shorten_name" placeholder="Zkratka produktu"
                    required>
            </div>
            <div class="col-12">
                <label for="type" class="mt-2">{{ $t("products.type") }}</label>
            </div>
            <div class="col-12">
                <input v-model="formData.type" type="radio" id="type" name="type" value="Měkké"> {{ $t("products.soft") }}<br>
                <input v-model="formData.type" type="radio" id="type" name="type" value="Tvrdé"> {{ $t("products.hard") }}<br>
                <input v-model="formData.type" type="radio" id="type" name="type" value="Doplňkový prodej"> {{ $t("products.addition") }}
            </div>
            <div class="col-12">
                <label for="name" class="mt-2">{{ $t("products.subtype") }}</label>
            </div>
            <div class="col-12">
                <input v-model="formData.subtype" type="text" class="form-control" id="subtype" name="subtype" placeholder="Podtyp">
            </div>
            <div class="col-12">
                <label for="length" class="mt-2">{{ $t("products.length") }}<span>*</span></label>
            </div>
            <div class="col-12">
                <input v-model="formData.length" type="number" class="form-control" id="length" name="length" placeholder="2" min="0" max="1000000"
                    required>
            </div>
            <div class="col-12">
                <label for="measurement_unit" class="mt-2">{{ $t("products.measureUnit") }}</label>
            </div>
            <div class="col-12">
                <input v-model="formData.measurement_unit" type="radio" id="measurement_unit" name="measurement_unit" value="PRMS"> PRMS<br>
                <input v-model="formData.measurement_unit" type="radio" id="measurement_unit" name="measurement_unit" value="PRMR"> PRMR
            </div>
            <ProductPriceForm :warehouses="warehouses" @price-submitted="handlePriceSubmitted" />
            <input @click="submitForm" type="submit" value="Vytvořit" class="mt-4 btn btn-primary w-100 form-submit">
        </form>
    </div>
</template>
<script>
import ProductPriceForm from "@/components/ProductPriceForm.vue";

export default {
    components: {
        ProductPriceForm
    },
    data() {
        return {
            warehouses: [],
            formData: {
                name: "",
                shorten_name: "",
                type: null,
                subtype: "",
                length: "",
                measurement_unit: null,
                priceForm: {}
            }
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.$store.getters.api.get("/warehouses").then((response) => {
                this.warehouses = response.data.items;
            });
        },
        handlePriceSubmitted(priceData) {
            this.formData.priceForm = Object.assign({}, this.formData.priceForm, priceData);
        },
        submitForm() {
            this.$store.getters.api.post("/product/add", this.formData)
                .then((response) => {
                    this.formData = response.data;
                    this.$router.push({"name": "Products"});

                });
        },
    }
}
</script>
<style scoped lang="scss"></style>