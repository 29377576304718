<template>
    <div class="col-12 mt-4">
        <h3 class="text-primary">{{ $t("warehouses.stockChanges") }}</h3>
    </div>
    <div class="col-12">
        <div class="w-100 row py-4 item-line">
            <div class="col-1">{{ $t("products.shortcut") }}</div>
            <div class="col-2">{{ $t("products.type") }}</div>
            <div class="col-1">{{ $t("products.subtype") }}</div>
            <div class="col-2">{{ $t("products.length") }}</div>
            <div class="col-2">{{ $t("products.measureUnit") }}</div>
            <div class="col-2">{{ $t("products.amountPieces") }}</div>
            <div class="col-2">{{ $t("products.timeDateChange") }}</div>
        </div>
        <div v-for="stock_changes in stockChanges" :key="stock_changes.id" :value="stock_changes.id"
            class="w-100 row py-4 item-line">
            <div class="col-1">{{ stock_changes.product_stock.product.shorten_name }}</div>
            <div class="col-2">{{ stock_changes.product_stock.product.type }}</div>
            <div class="col-1">{{ stock_changes.product_stock.product.subtype }}</div>
            <div class="col-2">{{ stock_changes.product_stock.product.length }}</div>
            <div class="col-2">{{ stock_changes.product_stock.product.measurement_unit }}</div>
            <div class="col-2">{{ stock_changes.amount }} ks</div>
            <div class="col-2">{{ stock_changes.created }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        stockChanges: {
            type: Array,
            default: () => [],
        }
    }
}
</script>
<style lang="">

</style>