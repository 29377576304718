<template>
    <div class="p-5">
        <h2>{{ $t("products.changePrice") }}</h2>
        <form @submit.prevent="submitForm" method="post" action="" class="row w-50" autocomplete="off">
            <div class="col-12">
                <h3>{{ $t("products.description") }}</h3>
            </div>
            <div class="col-12">
                <p>{{ $t("products.shortcut") }}: {{ product.shorten_name }}</p>
            </div>
            <div class="col-12">
                <p>{{ $t("products.name") }}: {{ product.name }}</p>
            </div>
            <div class="col-12">
                <p>{{ $t("products.type") }}: {{ product.type }}</p>
            </div>
            <div class="col-12">
                <p>{{ $t("products.length") }}: {{ product.length }} cm</p>
            </div>
            <div class="col-12">
                <p>{{ $t("products.currentVatPrice") }}: {{ $t("products.from") }}
                    <span v-if="product.pricesWithVat" v-for="(price, index) in product.pricesWithVat" :key="index"
                        class="me-2"> {{ price.amount }} {{ price.currency }}</span>
                    <span v-else>--</span>
                </p>
            </div>
            <ProductPriceForm :warehouses="warehouses" @price-submitted="handlePriceSubmitted" />
            <button @click="submitForm" type="submit" class="mt-4 btn btn-primary w-100 form-submit">
                {{ $t("utils.edit") }}
            </button>
        </form>
    </div>
</template>
<script>
import ProductPriceForm from "@/components/ProductPriceForm.vue";

export default {
    components: {
        ProductPriceForm
    },
    data() {
        return {
            warehouses: [],
            productId: null,
            product: {}
        }
    },
    created: function () {
        this.productId = this.$route.params.id;
        this.loadData();
    },
    methods: {
        loadData() {
            this.$store.getters.api.get("/product/" + this.productId).then((response) => {
                this.product = response.data.item;
            });
            this.$store.getters.api.get("/warehouses").then((response) => {
                this.warehouses = response.data.items;
            });
        },
        handlePriceSubmitted(priceData) {
            // Handle the emitted data here
            console.log("Price data submitted from child component:", priceData);
            // You can update the parent component's data or perform any other necessary actions
        },
        submitForm() {
            const priceData = {
                warehouse: this.warehouse,
                currency: this.currency,
                price: this.price,
                vat: this.vat,
                from_date: this.from_date,
            };
            this.$store.getters.api.put("/product/" + this.productId + "/change_price", priceData)
                .then((response) => {
                    this.priceData = response.data;
                    this.$router.push({ "name": "Products" });
                });
        }
    }
}
</script>
<style lang="">

</style>