<template>
    <div class="card card-body mt-2">
        <span>Nastavení objednávek a kalendáře</span>
        <div class="form-check">
            <input class="form-check-input check-input" type="checkbox" :checked="showOneDay"
                @input="$emit('showOneDayChanged', $event.target.checked)">
            <label class="form-check-label" for="flexCheckDefault">
                Zobrazit pouze 1 den
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input check-input" type="checkbox" :checked="showWithoutTravel"
                @input="$emit('showWithoutTravelChanged', $event.target.checked)">
            <label class="form-check-label" for="flexCheckDefault">
                Bez cesty
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input check-input" type="checkbox" :checked="showIds"
                @input="$emit('showIdsChanged', $event.target.checked)">
            <label class="form-check-label" for="flexCheckDefault">
                Ukázat ID objednávek
            </label>
        </div>
        <div class="d-flex align-items-center">
            <span class="float-left me-3">Od </span>
            <input class="form-control w-25" type="number" v-model="calendarFrom" min="0" :max="calendarTo - 1">
            <span class="float-left mx-3">do </span>
            <input class="form-control w-25" type="number" v-model="calendarTo" max="24" :min="calendarFrom + 1">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            updateTimeInterval: null,
            selectedOrder: null,
            showOneDay: false,
            showWithoutTravel: false,
            showIds: false,
            calendarFrom: 6,
            calendarTo: 18,
        }
    },
    watch: {
        calendarFrom: function (value) {
            if (this.calendarFrom > this.calendarTo) {
                this.calendarFrom = this.calendarTo - 1;
                return;
            }
            this.calendarFrom = value;
            this.$emit('calendarFromChanged', this.calendarFrom);
        },
        calendarTo: function (value) {
            if (this.calendarTo < this.calendarFrom) {
                this.calendarTo = this.calendarFrom + 1;
                return;
            }
            this.calendarTo = value;
            this.$emit('calendarToChanged', this.calendarTo);
        },
        showOneDay: function (newShowOneDay) {
            this.$emit('showOneDayChanged', newShowOneDay);
        },
        showIds: function (newShowIds) {
            this.$emit('showIdsChanged', newShowIds);
        },
        showWithoutTravel: function (newShowWithoutTravel) {
            this.$emit('showWithoutTravelChanged', newShowWithoutTravel);
        },
    },
    methods: {
        isSelected: function (i) {
            return i == this.selectedDate.getDate()
                && this.selectedDate.getMonth() == this.currentMonth
                && this.selectedDate.getFullYear() == this.currentYear;
        },
        isToday: function (i) {
            this.today = new Date();
            return !this.isSelected(i) && i == this.today.getDate()
                && this.today.getMonth() == this.currentMonth
                && this.today.getFullYear() == this.currentYear;
        },
        selectToday: function () {
            this.selectedDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
            this.updateCurrentMonthYear();
            this.updateSelectedWeekBySelectedDate();
        },
        selectDate: function (i) {
            this.selectedDate = new Date(this.currentYear, this.currentMonth, i);
            this.updateCurrentMonthYear();
            this.updateSelectedWeekBySelectedDate();
        },
        isTodayFullDate: function (date) {
            return this.sameDay(this.today, date);
        },
        isInSelectedTime: function () {
            var hours = this.today.getHours();
            if (hours >= this.calendarFrom && hours < this.calendarTo) {
                return true;
            }
            return false;
        },
        positionInCalendar: function (minutesPassed) {
            var totalDayMinutes = (this.calendarTo - this.calendarFrom) * 60;
            return ((minutesPassed / totalDayMinutes) * 100) + "%"
        },
        positionItem: function (datetime) {
            var totalMinutesPassed = (datetime.getHours() - this.calendarFrom) * 60 + datetime.getMinutes();
            return this.positionInCalendar(totalMinutesPassed);
        },
        positionOrder: function (order) {
            if (order.expected_delivery_date && order.expected_delivery_time) {
                var date = new Date(order.expected_delivery_date + " " + order.expected_delivery_time)
                return this.positionItem(date);
            }
        },
    }
}
</script>
<style lang="">

</style>