<template>
  <div class="p-5">
    <h2>{{ $t("warehouses.editWarehouse") }} - {{ warehouse.name }}</h2>
    <form @submit.prevent="submitForm" method="post" action="" class="row w-50" autocomplete="off">
      <div v-if="Object.keys(warehouse).length !== 0" class="col-12">
        <p>{{ warehouse.address.full_address }}</p>
      </div>
      <div class="col-12">
        <p>{{ product.shorten_name }}</p>
      </div>
      <div class="col-12">
        <p>{{ $t("warehouses.amountPieces") }}: {{ amount }}</p>
      </div>
      <div class="col-12">
        <label for="amount" class="mt-2">{{ $t("warehouses.piecesChange") }}</label>
      </div>
      <div class="col-12">
        <input v-model="formData.amount" placeholder="100" type="number" class="form-control" id="amount"
          name="amount" min="-1000000" max="1000000" required>
      </div>
      <button @click="submitForm" type="submit" class="mt-4 btn btn-primary w-100 form-submit">
        {{ $t("utils.edit") }}
      </button>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      warehouse: {},
      product: {},
      amount: null,
      formData: {
        amount: null
      },
      productStock: []
    };
  },
  methods: {
    loadData: function () {
      this.$store.getters.api.get("/warehouse/" + this.warehouseId).then((response) => {
        this.warehouse = response.data.item;
      });
      this.$store.getters.api.get("/product/" + this.productId).then((response) => {
        this.product = response.data.item;
      });
      this.$store.getters.api.get("/warehouse/" + this.warehouseId + "/" + "product_stock").then((response) => {
        this.productStock = response.data.items;
        // Find the product by ID
        const productIdToFind = parseInt(this.productId, 10); // Convert to integer
        const foundProduct = this.productStock.find((item) => item.product.id === productIdToFind);

        if (foundProduct) {
          this.amount = foundProduct.amount;
        }
      });
    },
    submitForm() {
      this.$store.getters.api.put("/update_stock/" + this.warehouseId + "/" + this.productId, this.formData)
        .then((response) => {
          this.formData = response.data;
          this.$router.push({ name: "WarehouseStock", params: { id: this.warehouseId } });
        });
    }
  },
  created() {
    this.warehouseId = this.$route.params.warehouse_id;
    this.productId = this.$route.params.product_id;
    this.loadData();
  },
};
</script>
<style lang="">

</style>