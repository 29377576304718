import 'bootstrap/dist/js/bootstrap.js'

import { createApp } from 'vue'
import router from '@/router.js'
import store from '@/store.js'
import locales from '@/locales'
import App from '@/App.vue'
import Icon from "@/components/Icon.vue"
import formatters  from "@/mixins/formaters.js";

const app = createApp(App)
app.use(locales)
app.use(router)
app.use(store)
app.mixin(formatters)
app.mount('#app')
app.component('icon', Icon)
