<template>
    <div>
        <div class="row">
            <slot name="filters"></slot>
        </div>
        <div class="row pt-3 pb-3">
            <slot name="header"></slot>
        </div>

        <div>
            <template v-for="(item, i) in items" v-key="i">
                <div class="row py-1 pt-2 align-items-center item">
                    <slot name="row" :item="item"></slot>
                </div>
            </template>
        </div>
        <LoadingSpinner :loading="loading" />
        <Pagination :totalCount="totalCount" :perPage="perPage" :currentPage="currentPage" @page-changed="updatePageData"
            @update:per-page="updatePerPage" />
    </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
    components: {
        Pagination,
        LoadingSpinner
    },
    props: {
        endpoint: {
            type: String,
            required: true
        },
        filters: {
            type: Object,
            required: false,
            default: {}
        }
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            totalCount: 0,
            items: [],
            loading: true,
            timeout: null
        }
    },
    created: function () {
        this.loadData();
    },
    watch: {
        filters: {
            handler: function () {
                this.loadData();
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        loadData() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            const data = {
                params: {
                    current_page: this.currentPage,
                    per_page: this.perPage,
                    ...this.filters
                }
            }
            this.timeout = setTimeout(function () {
                this.loading = true;
                this.$store.getters.api.get(this.endpoint, data).then((response) => {
                    this.items = response.data.items;
                    this.totalCount = response.data.total;
                    this.loading = false;
                });
            }.bind(this), 500)
        },
        updatePageData(page) {
            this.currentPage = page;
            this.loadData();
        },
        updatePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.currentPage = 1;
            this.loadData();
        },
    }
}
</script>

<style lang="scss">
.item {
    background-color: white;
    border-bottom: 1px solid grey;
    padding: 5px;
    min-height: 60px;
    &:nth-of-type(odd) {
        background-color: #eeeeee;
        border-bottom: 1px solid grey;
    }
    &:nth-of-type(1) {
        border-top: 1px solid grey;
    }
    button {
        background-color: transparent;
    }
}
</style>