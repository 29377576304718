<template>
    <div class="p-2 p-lg-5">
        <div class="row">
            <div class="col-6 ps-4 ms-2">
                <h2>{{ $t("products.products") }}</h2>
            </div>
            <div v-if="$store.getters.hasPerm('add_product')" class="col-auto ms-auto">
                <router-link :to="'/product/add'" class="button-top">{{ $t("products.add.add")
                }}</router-link>
            </div>
        </div>
        <Table endpoint="/products" ref="table" class="mt-3" :filters="filters">
            <template v-slot:header>
                <div class="col-2 col-lg-1">{{ $t("products.shortcut") }}</div>
                <div class="col-2 col-lg-1">{{ $t("products.type") }}</div>
                <div class="col-2 col-lg-2">{{ $t("products.subtype") }}</div>
                <div class="col-3 col-lg-1">{{ $t("products.length") }}</div>
                <div class="col-3 col-lg-1">{{ $t("products.measureUnit") }}</div>
                <div class="col-4 col-lg-2 mt-4 mt-lg-0">{{ $t("products.price") }}</div>
                <div class="col-4 col-lg-2 mt-4 mt-lg-0">{{ $t("products.vatPrice") }}</div>
            </template>
            <template #row="line">
                <div class="col-2 col-lg-1">{{ line.item.shorten_name }}</div>
                <div class="col-2 col-lg-1">{{ line.item.type }}</div>
                <div class="col-2 col-lg-2">{{ line.item.subtype }}</div>
                <div class="col-3 col-lg-1">{{ line.item.length || '-' }}</div>
                <div class="col-3 col-lg-1">{{ line.item.measurement_unit }}</div>
                <div class="col-4 col-lg-2 mt-4 mt-lg-0">
                    <p v-if="line.item.prices.length" v-for="(price, index) in line.item.prices"
                        v-key="index">{{ price.amount }} {{ price.currency }}</p>
                    <p v-else>--</p>
                </div>
                <div class="col-4 col-lg-2 mt-4 mt-lg-0">
                    <p v-if="line.item.pricesWithVat.length" v-for="(price, index) in line.item.pricesWithVat"
                        v-key="index">{{ price.amount, 'decimal' }} {{ price.currency }}</p>
                    <p v-else>--</p>
                </div>
                <div class="row col-4 col-lg-2 mt-4 mt-lg-0">
                    <div class="col-12">
                        <router-link :to="'/product/change-price/' + line.item.id" class="change">{{
                            $t("utils.changePrice")
                        }}</router-link>
                    </div>
                    <div class="col-12">
                        <button @click="deleteProduct(line.item.id)" v-if="$store.getters.hasPerm('delete_product')">
                            {{ $t("utils.dontOffer") }}
                        </button>
                    </div>
                </div>
            </template>
        </Table>
    </div>
</template>

<script>
import Table from "@/components/Table.vue";

export default {
    components: {
        Table
    },
    data() {
        return {
            filters: {
                internId: null,
                fullName: null,
                email: null,
                phone: null,
                address: null
            }
        }
    },
    methods: {
        deleteProduct: function (id) {
            this.$store.getters.api.delete("/product/" + id + "/delete").then((response) => {
                this.$refs.table.loadData();
            }).catch((response) => {
                alert(this.$t("error." + response?.response?.data?.error_code));
            });
        }
    },
}
</script>

<style lang="scss">
@import "@/scss/abstracts/variables";

button,
.change {
    text-decoration: none;
    border: none;
    padding: 0;
    margin: 0;
    color: $primary;
}
</style>