<template>
    <div>
        <EditableRow :editable="editable" :body="user.first_name" :header="$t('user.firstName')"/>
        <EditableRow :editable="editable" :body="user.last_name" :header="$t('user.lastName')"/>
        <EditableRow :editable="editable" :body="user.password" :header="$t('user.password')"/>
        <template v-if="editable">
            <select multiple v-model="user.functions">
                <option v-for="(func, i) in functions" v-key="i" :value="func">{{ $t("functions."+func.name) }}</option>
            </select>
        </template>
        <template v-else>
            <div>
                <p>{{ $t("user.functions") }}</p>
                <span v-if="user?.functions?.length >= 1"
                    v-for="(func, i) in user?.functions" v-key="i">
                    <span v-if="i > 0">, </span>
                    {{ $t("functions." + func.name) }}
                </span>
                <span v-else>-</span>
            </div>
        </template>
        <button @click="editable = !editable">{{  $t("utils.edit") }}</button>
        <button @click="save()">{{ $t("utils.save") }}</button>
    </div>
</template>

<script>
import EditableRow from "@/components/EditableRow.vue";

export default {
    components: {
        EditableRow
    },
    data() {
        return {
            editable: false,
            user: {},
            functions: [],
            userId: null
        }
    },
    created: function () {
        this.userId = this.$route.params.id;
        this.loadData();
    },
    methods: {
        loadData: function (functions) {
            this.$store.getters.api.get("/user/" + this.userId).then((response) => {
                this.user = response.data.item;
            }).catch((response) => {
                alert(this.$t("error." + response?.response?.data?.error_code));
                var status = response?.response?.status;
                if (status == 404) {
                    this.$router.push({"name": "users"});
                }
            });
            this.$store.getters.api.get("/functions").then((response) => {
                this.functions = response.data.items
            });
        },
        save: function () {
            
        }
    }
}
</script>

<style lang="scss"></style>