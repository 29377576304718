<template>
    <div class="card card-body">
        <span>Sklady</span>
        <div class="form-check" v-for="warehouse in warehouses">
            <input class="form-check-input check-input" type="checkbox" :id="warehouse.id" :value="warehouse.id"
                :style="{ accentColor: warehouse.color }" v-model="selectedWarehousesAll" @change="emitSelectedWarehouses">
            <label class="form-check-label" for="flexCheckDefault">
                {{ warehouse.name }}
            </label>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedWarehouses: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            warehouses: [],
            selectedWarehousesAll: []
        }
    },
    created: function () {
        this.getWarehouses();
    },
    watch: {
        selectedWarehouses: function () {
            this.selectedWarehousesAll = this.selectedWarehouses;
        }
    },
    methods: {
        emitSelectedWarehouses() {
            this.$emit('update:selectedWarehouses', this.selectedWarehousesAll);
        },
        getWarehouses: function () {
            var that = this;
            this.$store.getters.api.get('/warehouses/own').then(function (response) {
                this.warehouses = response.data.items;
                this.selectedWarehousesAll = this.selectedWarehouses;
                if (this.warehouses.length == 1 && !this.selectedWarehousesAll.includes(this.warehouses[0].id)) {
                    this.selectedWarehousesAll.push(this.warehouses[0].id);
                }
            }.bind(this));
        },
    }
}
</script>
<style lang="">

</style>