<template>
    <div class="row col-6 p-5">
        <AddCustomerForm  />
    </div>
</template>

<script>
import AddCustomerForm from "@/components/AddCustomerForm.vue";
export default {
    components: {
        AddCustomerForm
    },
    data() {
        return {

        }
    },
}
</script>

<style lang="">

</style>