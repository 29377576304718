<template>
    <div class="p-2 p-lg-5">
        <div class="row">
            <div class="col-6 ps-4 ms-2">
                <h2>{{ $t("titles.orders") }}</h2>
            </div>
            <div v-if="$store.getters.hasPerm('add_order')" class="col-auto ms-auto">
                <button @click="createOrder()" class="button-top">{{ $t("orders.addOrder")
                }}</button>

            </div>
        </div>
        <Table endpoint="/orders" ref="table" class="mt-3" :filters="filters">
            <template v-slot:filters>
                <div class="row pt-3">
                    <div class="col-3 col-lg-2">{{ $t("orders.id") }}</div>
                    <div class="col-3 col-lg-2" v-if="$store.getters.hasPerm('add_order')">{{ $t("orders.createdDate") }}</div>
                    <div class="col-3 col-lg-2">{{ $t("orders.deliveryDate") }}</div>
                    <div class="col-3 col-lg-2">{{ $t("orders.name") }}</div>
                    <div class="col-3 col-lg-2">{{ $t("orders.phone") }}</div>
                    <div class="col-3 col-lg-2">{{ $t("orders.address") }}</div>
                </div>
                <div class="row py-3 item-line">
                    <div class="col-3 col-lg-2">
                        <input type="text" class="form-control" placeholder="23010001" v-model="filters.internId">
                    </div>
                    <div class="col-3 col-lg-2" v-if="$store.getters.hasPerm('add_order')">
                        <input type="date" class="form-control"
                            v-model="filters.createdDateStart">
                        <input type="date" class="form-control"
                            v-model="filters.createdDateEnd">
                    </div>
                    <div class="col-3 col-lg-2">
                        <input type="date" class="form-control"
                            v-model="filters.expectedDeliveryDateStart">
                        <input type="date" class="form-control"
                            v-model="filters.expectedDeliveryDateEnd">
                    </div>
                    <div class="col-3 col-lg-2">
                        <input type="text" class="form-control" placeholder="Matěj Marek" v-model="filters.name">
                    </div>
                    <div class="col-3 col-lg-2">
                        <input type="text" class="form-control" placeholder="736109281" v-model="filters.wholePhone">
                    </div>
                    <div class="col-3 col-lg-2">
                        <input type="text" class="form-control" placeholder="Ulice 11, 110 00 Město"
                            v-model="filters.fullAddress">
                    </div>

                    <div class="row pt-2 fill-background">
                        <div class="col-8 col-lg-2 mt-4 mt-lg-0">{{ $t("orders.warehouse") }}</div>
                        <div class="col-8 col-lg-2 mt-4 mt-lg-0">{{ $t("orders.orderState") }}</div>
                        <div class="col-8 col-lg-2 mt-4 mt-lg-0" v-if="$store.getters.hasPerm('add_order')">{{ $t("orders.adtypes") }}</div>
                    </div>
                    <div class="row py-2">
                        <div class="col-8 col-lg-2 mt-4 mt-lg-0">
                            <select class="form-control form-select" id="warehouse" name="warehouse" v-model="filters.warehouse"
                                @change="searchFirstPage">
                                <option :value="null">Všechny</option>
                                <option v-for="warehouse in warehouses" :value="warehouse.id">{{ warehouse.name }}</option>
                            </select>
                        </div>
                        <div class="col-8 col-lg-2 mt-4 mt-lg-0">
                            <select class="form-control form-select" id="state" name="state" v-model="filters.state"
                                @change="searchFirstPage">
                                <option :value="null">vše</option>
                                <option value="not_done">nedokončeno</option>
                                <option value="done">dokončeno</option>
                                <!-- <option value="not_complete">nevyplněno</option> -->
                                <option value="hidden">zrušeno</option>
                            </select>
                        </div>
                        <div class="col-8 col-lg-2 mt-4 mt-lg-0" v-if="$store.getters.hasPerm('add_order')">
                            <select class="form-control form-select" id="state" name="state" v-model="filters.adtype"
                                @change="searchFirstPage">
                                <option :value="null">vše</option>
                                <option :value="adtype.id" v-for="adtype in adtypes" v-key="adtype.id">{{ adtype.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-8 col-lg-2 mt-4 mt-lg-0">
                            <button class="btn btn-primary btn-color-white" @click="clearFilters">{{ $t("utils.clearFilters") }}</button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:header>
                <div class="col-3 col-lg-1">{{ $t("orders.id") }}</div>
                <div class="col-3 col-lg-2">{{ $t("orders.deliveryDate") }}</div>
                <div class="col-3 col-lg-2">{{ $t("orders.name") }}</div>
                <div class="col-3 col-lg-2">{{ $t("orders.phone") }}</div>
                <div class="col-3">{{ $t("orders.address") }}</div>
                <div class="col-3 col-lg-2 d-flex">{{ $t("orders.action") }}</div>
            </template>
            <template #row="line">
                <div class="col-3 col-lg-1">{{ line.item.intern_id }}</div>
                <div class="col-3 col-lg-2">{{ line.item.expected_delivery_date }}</div>
                <div class="col-3 col-lg-2">{{ line.item.customer.name }}</div>
                <div class="col-3 col-lg-2">{{ line.item.customer.whole_phone }}</div>
                <div class="col-3">{{ line.item.customer.address.full_address }}</div>
                <div class="col-3 col-lg-2 d-flex">
                    <router-link :to="'/order/' + line.item.id" class="button link-darker mx-2 ms-0">{{
                        $t("utils.detail")
                    }}</router-link>
                    <router-link :to="'/order/' + line.item.id + '/items'" v-if="!line.item.hidden && $store.getters.hasPerm('change_order')" class="button link-darker mx-2">{{ $t("utils.edit")
                    }}</router-link>
                    <button @click="hideOrder(line.item.id)" v-if="!line.item.hidden && $store.getters.hasPerm('change_order')" class="button link-darker mx-2">{{ $t("utils.cancel") }}</button>
                    <button @click="restoreOrder(line.item.id)" v-else-if="$store.getters.hasPerm('change_order')" class="button link-darker mx-2">{{ $t("utils.restore") }}</button>
                </div>
            </template>
        </Table>
    </div>
</template>
<script>
import Table from "@/components/Table.vue";

export default {
    components: {
        Table
    },
    data() {
        return {
            warehouses: [],
            adtypes: [],
            filters: {
                internId: null,
                expectedDeliveryDateStart: null,
                expectedDeliveryDateEnd: null,
                name: null,
                wholePhone: null,
                fullAddress: null,
                warehouse: null,
                adtype: null,
                createdDateStart: null,
                createdDateEnd: null,
                state: null
            }
        }
    },
    created: function () {
        this.loadData();
        if (this.$store.getters.orderFilters) {
            this.filters = this.$store.getters.orderFilters;
        }
    },
    watch: {
        filters: {
            handler: function () {
                console.log(this.filters);
                this.$store.commit("updateOrderFilters", this.filters);
            },
            deep: true
        },
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get("/warehouses/own").then((response) => {
                this.warehouses = response.data.items;
            });
            if (this.$store.getters.hasPerm('add_order')) {
                this.$store.getters.api.get('/adtypes').then(function (response) {
                    this.adtypes = response.data.items;
                }.bind(this));
            }
        },
        hideOrder: function (id) {
            this.$store.getters.api.delete("/order/" + id + "/delete").then((response) => {
                this.$refs.table.loadData();
            }).catch((response) => {
                alert(this.$t("error." + response?.response?.data?.error_code));
            });
        },
        restoreOrder: function (id) {
            this.$store.getters.api.put("/order/" + id + "/restore").then((response) => {
                this.$refs.table.loadData();
            }).catch((response) => {
                alert(this.$t("error." + response?.response?.data?.error_code));
            });
        },
        createOrder: function () {
            this.$store.getters.api.get("/order/add").then((response) => {
                this.$router.push({name: "UpdateOrderCustomer", params: {id: response.data.id}})
            })
        },
        clearFilters: function () {
            this.filters = {
                internId: null,
                expectedDeliveryDateStart: null,
                expectedDeliveryDateEnd: null,
                name: null,
                wholePhone: null,
                fullAddress: null,
                warehouse: null,
                adtype: null,
                createdDateStart: null,
                createdDateEnd: null,
                state: null
            }
        }
    },
}
</script>
<style lang="">

</style>