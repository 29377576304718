<template>
    <component :is="icon" />
    <!-- class="icon" :style="{height: height + 'px'}" v-bind="$attrs" -->
</template>

<script>
import HandFinger from "@/components/icons/HandFinger.vue";
import Message from "@/components/icons/Message.vue";
import FileDownload from "@/components/icons/FileDownload.vue";
import School from "@/components/icons/School.vue";
import User from "@/components/icons/User.vue";
import Users from "@/components/icons/Users.vue";
import PlayerFirst from "@/components/icons/PlayerFirst.vue";
import PlayerLast from "@/components/icons/PlayerLast.vue";
import PlayerNext from "@/components/icons/PlayerNext.vue";
import PlayerPrev from "@/components/icons/PlayerPrev.vue";
import ArrowUp from "@/components/icons/ArrowUp.vue";
import ArrowDown from "@/components/icons/ArrowDown.vue";
import QuestionMark from "@/components/icons/QuestionMark.vue";
import Mail from "@/components/icons/Mail.vue";
import ChevronDown from "@/components/icons/ChevronDown.vue";
import ChevronDownWhite from "@/components/icons/ChevronDownWhite.vue";
import Search from "@/components/icons/Search.vue";
import Edit from "@/components/icons/Edit.vue";
import Phone from "@/components/icons/Phone.vue";
import Plus from "@/components/icons/Plus.vue";
import MagnifyingGlass from "@/components/icons/MagnifyingGlass.vue";
import Pointer from "@/components/icons/Pointer.vue";
import Calendar from "@/components/icons/Calendar.vue";
import Filter from "@/components/icons/Filter.vue";
import Pickup from "@/components/icons/Pickup.vue";
import DeliveryMan from "@/components/icons/DeliveryMan.vue";
import DeliveryMan2 from "@/components/icons/DeliveryMan2.vue";
import Truck from "@/components/icons/Truck.vue";
import UserPicture from "@/components/icons/UserPicture.vue";
import Snow from "@/components/icons/Snow.vue";
import Trash from "@/components/icons/Trash.vue";
import Info from "@/components/icons/Info.vue";

export default {
    name: "icon",
    // inheritAttrs: false,
    props: {
        icon: {type: String, required: true},
        // width: {type: Number, required: false, default: 24},
        // height: {type: Number, required: false, default: 24}
    },
    components: {
        PlayerFirst,
        PlayerLast,
        PlayerNext,
        PlayerPrev,
        HandFinger,
        Message,
        FileDownload,
        School,
        User,
        Users,
        ArrowUp,
        ArrowDown,
        QuestionMark,
        Mail,
        ChevronDown,
        ChevronDownWhite,
        Search,
        Edit,
        Phone,
        Plus,
        MagnifyingGlass,
        Pointer,
        Calendar,
        Filter,
        Pickup,
        DeliveryMan,
        DeliveryMan2,
        Truck,
        UserPicture,
        Snow,
        Trash,
        Info
    }
}
</script>

<style lang="scss" scoped>
    .icon {
        display: inline-block;
        vertical-align: middle;
    }
</style>