<template>
    <div class="row p-5">
        <div class="col-12 px-5">
            <div class="row">
                <div v-if="Object.keys(warehouse).length !== 0" class="col-12">
                    <h2>{{ $t("orders.warehouse") }} - {{ warehouse.name }}</h2>
                    <p>{{ warehouse.address.full_address }}</p>
                </div>
                <div class="col-12 mt-4">
                    <h3>{{ $t("warehouses.stock") }}</h3>
                </div>
                <Table :endpoint="'/warehouse/' + warehouseId + '/product_stock'" ref="table" class="mt-3">
                    <template v-slot:header>
                        <div class="col-1">{{ $t("products.shortcut") }}</div>
                        <div class="col-2">{{ $t("products.type") }}</div>
                        <div class="col-1">{{ $t("products.subtype") }}</div>
                        <div class="col-2">{{ $t("products.length") }}</div>
                        <div class="col-2">{{ $t("products.measureUnit") }}</div>
                        <div class="col-2">{{ $t("products.amountPieces") }}</div>
                    </template>
                    <template #row="line">
                        <div class="col-1">{{ line.item.product.shorten_name }}</div>
                        <div class="col-2">{{ line.item.product.type }}</div>
                        <div class="col-1">{{ line.item.product.subtype }}</div>
                        <div class="col-2">{{ line.item.product.length }}</div>
                        <div class="col-2">{{ line.item.product.measurement_unit }}</div>
                        <div class="col-2">{{ line.item.amount }} ks</div>
                        <router-link v-if="this.$store.getters.hasPerm('change_warehouse')" class="col-2"
                            :to="'/update-stock/' + warehouseId + '/' + line.item.product.id">
                            {{ $t("utils.editStock") }}
                        </router-link>
                    </template>
                </Table>
                <div v-if="$store.getters.hasPerm('change_warehouse')">
                    <WarehouseStockChanges :stockChanges="stockChanges" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Table from "@/components/Table.vue";
import WarehouseStockChanges from "../components/WarehouseStockChanges.vue";

export default {
    components: {
        Table,
        WarehouseStockChanges
    },
    data() {
        return {
            warehouseId: null,
            warehouse: {},
            stockChanges: []
        }
    },
    created() {
        this.warehouseId = this.$route.params.id;
        this.loadData();
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get("/warehouse/" + this.warehouseId).then((response) => {
                this.warehouse = response.data.item;
            });
            this.$store.getters.api.get("/product_stock_changes/" + this.warehouseId).then((response) => {
                this.stockChanges = response.data.items;
            });
        }
    },
}
</script>

<style lang="scss">
@import "@/scss/abstracts/variables";

.item-line {
    border-bottom: 1px solid black;
    align-items: center;
}

button,
.change {
    text-decoration: none;
    border: none;
    padding: 0;
    margin: 0;
    color: $primary;
}
</style>