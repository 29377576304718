<template>
    <div v-if="Object.keys(warehouse).length !== 0" class="p-5">
        <h2>Upravení skladu - {{ warehouse.name }}</h2>
        <form @submit.prevent="submitForm" method="post" action="" class="row w-50" autocomplete="off">
            <div  class="col-12">
                <p>{{ warehouse.address.full_address }}</p>
            </div>
            <AddDrivers :drivers="drivers" @drivers-selected="handleDriverSelected" />
            <AddWarehouseman :warehousemen="warehousemen" @warehousemen-selected="handleWarehousemanSelected" />
            <button @click="submitForm()" type="submit" class="mt-4 btn btn-primary w-100 form-submit">Upravit</button>
        </form>
    </div>
</template>
<script>
import AddDrivers from "@/components/AddDrivers.vue";
import AddWarehouseman from "@/components/AddWarehouseman.vue";

export default {
    components: {
        AddDrivers,
        AddWarehouseman
    },
    data() {
        return {
            warehouse: {},
            drivers: [],
            warehousemen: [],
            warehouseId: null,
            formData: {
                drivers: [],
                warehousemen: []
            }
        }
    },
    created() {
        this.warehouseId = this.$route.params.id;
        this.loadData();
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get("/warehouse/" + this.warehouseId).then((response) => {
                this.warehouse = response.data.item;
                this.drivers = response.data.item.drivers;
                this.warehousemen = response.data.item.warehousemen;
            });
            this.$store.getters.api.get("/drivers").then((response) => {
                this.drivers = response.data.items;
            });
            this.$store.getters.api.get("/warehousemen").then((response) => {
                this.warehousemen = response.data.items;
            });
        },
        handleDriverSelected(selectedDrivers) {
            this.formData.drivers = selectedDrivers
        },
        handleWarehousemanSelected(selectedWarehousemen) {
            this.formData.warehousemen = selectedWarehousemen
        },
        submitForm() {
            this.$store.getters.api.put("/warehouse/" + this.warehouseId + "/edit", this.formData)
            .then((response) => {
                    this.formData = response.data;
                    this.$router.push({ "name": "warehouses" });
                });
        }
    },
}
</script>
<style lang="scss"></style>