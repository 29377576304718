<template>
    <div class="p-5">
        <h2>{{ $t("vacations.edit") }}</h2>
        <form @submit.prevent="submitForm" method="post" action="" class="row w-50" autocomplete="off">
            <div class="col-12">
                <label for="warehouse" class="mt-2">{{ $t("vacations.warehouse") }}</label>
            </div>
            <div class="col-12">
                <select v-model="formData.warehouseId" class="form-control form-select" id="warehouse" name="warehouse"
                    size="1">
                    <option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">
                        {{ warehouse.name }}
                    </option>
                </select>
            </div>
            <div class="col-12">
                <label for="vacation_from" class="mt-2">{{ $t("vacations.from") }}</label>
            </div>
            <div class="col-12">
                <input v-model="formData.vacation_from" type="datetime-local" class="form-control" id="vacation_from"
                    name="vacation_from">
            </div>
            <div class="col-12">
                <label for="vacation_to" class="mt-2">{{ $t("vacations.to") }}</label>
            </div>
            <div class="col-12">
                <input v-model="formData.vacation_to" type="datetime-local" class="form-control" id="vacation_to"
                    name="vacation_to" placeholder="">
            </div>

            <button @click="submitForm" type="submit" class="mt-4 btn btn-primary w-100 form-submit">{{ $t("utils.add")
            }}</button>
        </form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            warehouses: [],
            vacationId: null,
            formData: {
                warehouseId: null,
                vacation_from: null,
                vacation_to: null
            }
        }
    },
    created: function () {
        this.vacationId = this.$route.params.id;
        this.loadData();
    },
    methods: {
        loadData() {
            this.$store.getters.api.get("/warehouses").then((response) => {
                this.warehouses = response.data.items;
            });
            this.$store.getters.api.get("/vacation/" + this.vacationId).then((response) => {
                this.formData.warehouseId = response.data.item.warehouse.id;
                this.formData.vacation_from = response.data.item.vacation_from;
                this.formData.vacation_to = response.data.item.vacation_to;
            });
        },
        submitForm() {
            const payload = {
                warehouse: this.formData.warehouseId,
                vacation_from: this.formData.vacation_from,
                vacation_to: this.formData.vacation_to
            };
            this.$store.getters.api.put("/vacation/" + this.vacationId + "/edit", payload)
                .then((response) => {
                    this.$router.push({ "name": "Vacations" });
                });
        }
    },

}
</script>
<style scoped lang="scss"></style>