<template>
    <div id="calendar" class="row">
        <div class="col-12 col-lg-3">
            <div class="calendar sticky-top">
                <div v-if="$store.getters.hasPerm('add_order')">
                    <button @click="createOrder()" class="btn button-order">{{ $t("orders.addOrder") }}</button>
                </div>
                <SmallCalendar :selectedDate="selectedDate" @update:selectedDate="handleSelectedDateUpdate"/>
                <CalendarWarehouses @update:selectedWarehouses="handleSelectedWarehousesUpdate" :selectedWarehouses="selectedWarehouses"/>
                <CalendarSettings @calendarFromChanged="handleCalendarFromChanged"
                    @calendarToChanged="handleCalendarToChanged" @showOneDayChanged="handleShowOneDayChanged"
                    @showIdsChanged="handleShowIdsChanged" @showWithoutTravelChanged="handleShowWithoutTravelChanged" />
            </div>
        </div>
        <div class="col-12 col-lg-9">

            <!-- Month Selector moved to CalendarDayColumns -->

            <CalendarDayColumns :selectedWarehouses="selectedWarehouses" :showOneDay="showOneDay" :showIds="showIds"
                :calendarFrom="calendarFrom" :calendarTo="calendarTo" :showTravelTime="showTravelTime"
                @update:selectedOrder="handleSelectedOrderUpdate" :selectedDate="selectedDate" @update:selectedDate="handleSelectedDateUpdate"/>

        </div>
        <div v-if="selectedOrder">
            <CalendarOrderDetail :selectedOrder="selectedOrder" @update:selectedOrder="handleSelectedOrderUpdate" />
        </div>
    </div>
</template>

<script>
import SmallCalendar from "@/components/SmallCalendar.vue";
import CalendarWarehouses from "@/components/CalendarWarehouses.vue";
import CalendarSettings from "@/components/CalendarSettings.vue";
import CalendarDayColumns from "@/components/CalendarDayColumns.vue";
import CalendarOrderDetail from "@/components/CalendarOrderDetail.vue";

export default {
    components: {
        SmallCalendar,
        CalendarWarehouses,
        CalendarSettings,
        CalendarDayColumns,
        CalendarOrderDetail
    },
    data() {
        return {
            selectedWarehouses: [],
            today: new Date(),
            selectedDate: new Date(),
            selectedWeek: [],
            currentYear: null,
            currentMonth: null,
            updateTimeInterval: null,
            selectedOrder: null,
            showOneDay: false,
            showTravelTime: true,
            showIds: false,
            calendarFrom: 6,
            calendarTo: 18,
            months: [
                "Leden",
                "Únor",
                "Březen",
                "Duben",
                "Květen",
                "Červen",
                "Červenec",
                "Srpen",
                "Září",
                "Říjen",
                "Listopad",
                "Prosinec"
            ],
            dayShortName: [
                "Ne",
                "Po",
                "Út",
                "St",
                "Čt",
                "Pá",
                "So"
            ]
        };
    },
    created: function () {
        // interval to update time
        this.updateTimeInterval = setInterval(function () {
            this.today = new Date();
        }.bind(this), 60000);
    },
    mounted: function () {
        this.selectedWarehouses = this.$store.getters.dashboardFilters.selectedWarehouses;
        this.selectedDate = this.$store.getters.dashboardFilters.selectedDate;
        this.selectedOrder = this.$store.getters.dashboardFilters.selectedOrder;
        if (this.selectedDate == null) {
            this.currentMonth = this.today.getMonth();
            this.currentYear = this.today.getFullYear();
            this.selectedDate = new Date(this.currentYear, this.currentMonth, this.today.getDate());
        }
    },
    watch: {
        calendarFrom: function (value) {
            if (this.calendarFrom > this.calendarTo) {
                this.calendarFrom = this.calendarTo - 1;
                return;
            }
            this.calendarFrom = value;
        },
        calendarTo: function (value) {
            if (this.calendarTo < this.calendarFrom) {
                this.calendarTo = this.calendarFrom + 1;
                return;
            }
            this.calendarTo = value;
        },
        selectedOrder: function (value) {
            if (value == null) {
                document.getElementById("calendar").style.overflowY = "scroll";
            }
            else {
                document.getElementById("calendar").style.overflowY = "hidden";
            }
            var filters = this.$store.getters.dashboardFilters;
            filters.selectedOrder = value;
            this.$store.commit("setDashboardFilters", filters);
        },
        selectedWarehouses: function (value) {
            var filters = this.$store.getters.dashboardFilters;
            filters.selectedWarehouses = value;
            this.$store.commit("setDashboardFilters", filters);
        },
        selectedDate: function (value) {
            var filters = this.$store.getters.dashboardFilters;
            filters.selectedDate = value;
            this.$store.commit("setDashboardFilters", filters);
        }
    },
    computed: {
        daysInMonth: function () {
            return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
        },
        selectedWeekNumber: function () {
            var startDate = new Date(this.selectedDate.getFullYear(), 0, 1);
            var days = Math.floor((this.selectedDate - startDate) /
                (24 * 60 * 60 * 1000)) + 1;
            return Math.ceil(days / 7);
        },
        selectedWeekEven: function () {
            return this.selectedWeekNumber % 2 ? "lichý" : "sudý";
        },
        daysInPreviosMonth: function () {
            var actualStart = new Date(this.currentYear, this.currentMonth, 1).getDay();
            // remove number of days
            if (actualStart == 0) {
                actualStart = 6;
            } else {
                actualStart -= 1;
            }
            var previousMonth = this.currentMonth - 1;
            var previousYear = this.currentYear;
            if (previousMonth <= -1) {
                previousMonth = 11;
                previousYear -= 1;
            }
            // create date list
            var dates = [];
            var maxDates = new Date(previousYear, previousMonth + 1, 0).getDate();
            for (var i = maxDates - actualStart + 1; i <= maxDates; i++) {
                dates.push(i);
            }
            return dates;
        },
        daysInNextMonth: function () {
            var actualEnd = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
            // remove number of days
            actualEnd = new Date(this.currentYear, this.currentMonth, actualEnd).getDay();
            if (actualEnd == 0) {
                actualEnd = 7;
            }
            // create date list
            var dates = [];
            for (var i = 1; i <= 7 - actualEnd; i++) {
                dates.push(i);
            }
            return dates;
        },
        positionTime: function () {
            return this.positionItem(this.today);
        },
        selectedDateCalendar: function () {
            for (var i = 0; i < this.selectedWeek.length; i++) {
                if (this.sameDay(this.selectedWeek[i].date, this.selectedDate)) {
                    return this.selectedWeek[i];
                }
            }
        }
    },
    methods: {
        handleSelectedWarehousesUpdate(selectedWarehouses) {
            this.selectedWarehouses = selectedWarehouses;
        },
        handleSelectedOrderUpdate(selectedOrder) {
            this.selectedOrder = selectedOrder;
        },
        handleSelectedDateUpdate(selectedDate) {
            this.selectedDate = selectedDate;
        },
        createOrder: function () {
            this.$store.getters.api.get("/order/add").then((response) => {
                this.$router.push({ name: "UpdateOrderCustomer", params: { id: response.data.id } })
            })
        },
        handleCalendarFromChanged(value) {
            this.calendarFrom = value
            // Do something with the updated value
        },
        handleCalendarToChanged(value) {
            this.calendarTo = value

            // Do something with the updated value
        },
        handleShowOneDayChanged(value) {
            this.showOneDay = value;
        },
        handleShowIdsChanged(value) {
            this.showIds = value
        },
        handleShowWithoutTravelChanged(value) {
            this.showTravelTime = !value;
        },
    }
};
</script>


<style scoped lang="scss"></style>