<template>
    <div class="p-5">
        <h2>{{ $t("vacations.add") }}</h2>
        <form @submit.prevent="submitForm" method="post" action="" class="row w-50" autocomplete="off">
            <div class="col-12">
                <label for="warehouse" class="mt-2">{{ $t("vacations.warehouse") }}</label>
            </div>
            <div class="col-12">
                <select v-model="formData.warehouse" class="form-control form-select" id="warehouse" name="warehouse"
                    size="1" required>
                    <option v-for="warehouse in warehouses" v-key="warehouse.id" :value="warehouse.id">{{ warehouse.name }},
                        {{ warehouse.address.full_address }}</option>
                </select>
            </div>
            <div class="col-12">
                <label for="vacation_from" class="mt-2">{{ $t("vacations.from") }}</label>
            </div>
            <div class="col-12">
                <input v-model="formData.vacation_from" type="datetime-local" class="form-control" id="vacation_from"
                    name="vacation_from" required>
            </div>
            <div class="col-12">
                <label for="vacation_to" class="mt-2">{{ $t("vacations.to") }}</label>
            </div>
            <div class="col-12">
                <input v-model="formData.vacation_to" type="datetime-local" class="form-control" id="vacation_to"
                    name="vacation_to" placeholder="" required>
            </div>

            <button @click="submitForm" type="submit" class="mt-4 btn btn-primary w-100 form-submit">{{ $t("utils.add")
            }}</button>
        </form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            warehouses: [],
            formData: {
                warehouse: null,
                vacation_from: null,
                vacation_to: null
            }
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.$store.getters.api.get("/warehouses").then((response) => {
                this.warehouses = response.data.items;
            });
        },
        submitForm() {
            this.$store.getters.api.post("/vacation/add", this.formData)
                .then((response) => {
                    this.formData = response.data;
                    this.$router.push({"name": "Vacations"});
                });
        }
    },

}
</script>
<style scoped lang="scss"></style>