<template>
  <nav class="navbar navbar-expand-lg fixed-top bg-secondary">
    <button class="navbar-toggler ms-auto me-4"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup"
    aria-expanded="false"
    aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse bg-secondary" id="navbarNavAltMarkup">
      <div class="navbar-nav ms-auto">
        <router-link class="nav-link px-4 text-nowrap" to="/dashboard">
          {{ $t("navbar.dashboard") }}
        </router-link>
        <router-link v-if="this.$store.getters.hasPerm('view_order')" class="nav-link px-4 text-nowrap" to="/orders">
          {{ $t("navbar.orders") }}
        </router-link>
        <router-link v-if="this.$store.getters.hasPerm('view_customer')" class="nav-link px-4 text-nowrap" to="/customers">
          {{ $t("navbar.customers") }}
        </router-link>
        <router-link v-if="this.$store.getters.hasPerm('view_calculation')" class="nav-link px-4 text-nowrap" to="/calculations">
          {{ $t("navbar.calculations") }}
        </router-link>
        <router-link v-if="this.$store.getters.hasPerm('view_vacations')" class="nav-link px-4 text-nowrap" to="/vacations">
          {{ $t("navbar.vacations") }}
        </router-link>
        <router-link v-if="this.$store.getters.hasPerm('view_warehouse')" class="nav-link px-4 text-nowrap" to="/warehouses">
          {{ $t("navbar.warehouses") }}
        </router-link>
        <router-link v-if="this.$store.getters.hasPerm('view_product')" class="nav-link px-4 text-nowrap" to="/products">
          {{ $t("navbar.products") }}
        </router-link>
        <router-link class="nav-link px-4 text-nowrap" to="/overviews">
          {{ $t("navbar.overviews") }}
        </router-link>
        <router-link v-if="this.$store.getters.hasPerm('view_user')" class="nav-link px-4 text-nowrap" to="/users">
          {{ $t("navbar.users") }}
        </router-link>
        <router-link class="nav-link px-4 text-nowrap" to="/account">
          {{ $t("navbar.myProfile") }}
        </router-link>
        <router-link class="nav-link px-4 text-nowrap" to="/logout">
          {{ $t("navbar.logout") }}
        </router-link>
        <ChangeLang class="mx-4 my-2 my-md-1" />
      </div>
    </div>
  </nav>
</template>


<script>
import ChangeLang from "@/components/ChangeLang.vue"
export default {
  components: {
    ChangeLang
  },
  data() {
    return {
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/variables";
.navbar-brand {
  font-weight: 600;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.nav-link {
  font-weight: 500;
  font-size: 1.2rem;
}

#logo {
  max-height: 60px;
}
</style>