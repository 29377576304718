export default {
    methods: {
        formatNumber: function(number, digits = 0) {
            if (number === null) {
                return this.$t("utils.novalue")
            }
            if (!number) {
                return "0"
            }
            var formatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: digits, minimumFractionDigits: digits });
            return formatter.format(number);
        }
    }
}