<template>
    <div class="row g-2">
        <!-- Available drivers -->
        <div class="col-5 available ms-3">
            <div class="col-12">
                <label for="drivers" class="">{{ $t("warehouses.driversAvailable") }}</label>
            </div>
            <ul>
                <li v-for="driver in drivers" :key="driver.id" :value="driver" @click="moveToSelected(driver)">
                    {{ driver.username }}
                </li>
            </ul>
        </div>
        <!-- Selected drivers -->
        <div class="col-5 selected mx-auto ms-3">
            <div class="col-12">
                <label for="drivers" class="">{{ $t("warehouses.driversSelected") }}</label>
            </div>
            <ul>
                <li v-for="driver in selectedDrivers" :key="driver.id" :value="driver" @click="moveToAllDrivers(driver)">
                    {{ driver.username }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        drivers: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedDrivers: [],
        };
    },
    methods: {
        emitDriverSelected() {
            this.$emit('drivers-selected', this.selectedDrivers);
        },
        moveToSelected(driver) {
            const index = this.drivers.indexOf(driver);
            if (index !== -1) {
                this.selectedDrivers.push(this.drivers.splice(index, 1)[0]);
                this.emitDriverSelected();
            }
        },
        moveToAllDrivers(driver) {
            const index = this.selectedDrivers.indexOf(driver);
            if (index !== -1) {
                this.drivers.push(this.selectedDrivers.splice(index, 1)[0]);
                this.emitDriverSelected();
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

ul {
    list-style-type: none;
    padding: 0;
    border: 1px solid $black;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

li {
    cursor: pointer;
    padding: 5px;
    margin: 5px 0;
    border: 1px solid $white;
    border-radius: 3px;
    font-weight: bold;
    transition: background-color 0.2s ease-in-out;
}

li:hover {
    font-weight: 600;
    color: $white;
    background-color: $primary;
}

li:active {
    background-color: $grey;
}
</style>

