<template>
    <div class="p-5">
        <div class="row">
            <div class="col-6">
                <h3>{{ $t("customer.editCustomer") }} - {{ customer.intern_id }}</h3>
                <form @submit.prevent="submitForm" method="post" action="" class="row" autocomplete="off">
                    <div class="col-6">
                        <label for="first_name" class="mt-2">{{ $t("customer.firstName") }}<span>*</span></label>
                    </div>
                    <div class="col-6">
                        <label for="last_name" class="mt-2">{{ $t("customer.lastName") }}<span>*</span></label>
                    </div>
                    <div class="col-6">
                        <input v-model="formData.first_name" type="text" class="form-control" id="first_name"
                            name="first_name" required>
                    </div>
                    <div class="col-6">
                        <input v-model="formData.last_name" type="text" class="form-control" id="last_name" name="last_name"
                            required>
                    </div>
                    <div class="col-4">
                        <label for="organization_id" class="mt-2">{{ $t("customer.organizationId") }}</label>
                    </div>
                    <div class="col-8">
                        <label for="organization_name" class="mt-2">{{ $t("customer.organizationName") }}</label>
                    </div>
                    <div class="col-4">
                        <input v-model="formData.organization_id" v-if="customer.organization_name" type="text"
                            class="form-control" id="organization_id" name="organization_id" placeholder="03804445">
                        <input v-model="formData.organization_id" v-else type="text" class="form-control"
                            id="organization_id" name="organization_id" placeholder="03804445">
                    </div>
                    <div class="col-8">
                        <input v-model="formData.organization_name" v-if="customer.organization_name" type="text"
                            class="form-control" id="organization_name" name="organization_name"
                            placeholder="Les Profi s.r.o.">
                        <input v-model="formData.organization_name" v-else type="text" class="form-control"
                            id="organization_name" name="organization_name" placeholder="Les Profi s.r.o.">
                    </div>
                    <div class="col-12 mt-4">
                        <label for="phone" class="mt-2">{{ $t("customer.phone") }}<span>*</span></label>
                    </div>
                    <div class="col-3">
                        <select v-model="formData.pre_phone" name="pre_phone" id="pre_phone" class="form-control" required>
                            <option v-if="customer.pre_phone === '+420'" value="+420" selected>+420</option>
                            <option v-else value="+420">+420</option>
                            <option v-if="customer.pre_phone === '+421'" value="+421" selected>+421</option>
                            <option v-else value="+421">+421</option>
                            <option v-if="customer.pre_phone === '+43'" value="+43" selected>+43</option>
                            <option v-else value="+43">+43</option>
                        </select>
                    </div>
                    <div class="col-9">
                        <input v-model="formData.phone" type="phone" class="form-control" id="phone" name="phone"
                            placeholder="736 109 281" required>
                    </div>
                    <div class="col-12">
                        <label for="email" class="mt-2">{{ $t("customer.email") }}</label>
                    </div>
                    <div class="col-12">
                        <input v-model="formData.email" v-if="customer.email" type="email" class="form-control" id="email"
                            name="email" placeholder="marek@digitalwish.cz">
                        <input v-model="formData.email" v-else type="email" class="form-control" id="email" name="email"
                            placeholder="marek@digitalwish.cz">
                    </div>
                    <div class="col-12 mt-4">
                        <label for="address" class="mt-2">{{ $t("customer.findAddress") }}</label>
                    </div>
                    <div class="col-12">
                        <AddressFinder :getData="addressData" />
                    </div>
                    <div class="col-6">
                        <label for="final_address" class="mt-2">{{ $t("customer.address") }}<span>*</span></label>
                    </div>
                    <div class="col-6">
                        <label for="town" class="mt-2">{{ $t("customer.town") }}<span>*</span></label>
                    </div>
                    <div class="col-6">
                        <input v-model="formData.final_address" type="text" class="form-control" id="final_address"
                            name="final_address" placeholder="Korunní 2569/108" required>
                    </div>
                    <div class="col-6">
                        <input v-model="formData.town" type="text" class="form-control" id="town" name="town"
                            placeholder="Praha 10" required>
                    </div>
                    <div class="col-3">
                        <label for="zip" class="mt-2">{{ $t("customer.zip") }}<span>*</span></label>
                    </div>
                    <div class="col-9">
                        <label for="country" class="mt-2">{{ $t("customer.state") }}<span>*</span></label>
                    </div>
                    <div class="col-3">
                        <input v-model="formData.zip" type="text" class="form-control" id="zip" name="zip"
                            placeholder="11000" required>
                    </div>
                    <div class="col-9">
                        <input v-model="formData.country" type="text" class="form-control" id="country" name="country"
                            placeholder="Česká republika" required>
                    </div>
                    <div class="col-6">
                        <label for="lon" class="mt-2">{{ $t("customer.lon") }}<span>*</span></label>
                    </div>
                    <div class="col-6">
                        <label for="lat" class="mt-2">{{ $t("customer.lat") }}<span>*</span></label>
                    </div>
                    <div class="col-6">
                        <input v-model="formData.lat" type="text" class="form-control" id="lat" name="lat"
                            placeholder="49,2131798130803" required>
                    </div>
                    <div class="col-6">
                        <input v-model="formData.lon" type="text" class="form-control" id="lon" name="lon"
                            placeholder="17,5175885924409" required>
                    </div>
                    <button @click="submitForm()" class="mt-4 btn btn-primary w-100 form-submit">
                        {{ $t("utils.save") }}
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import AddressFinder from "@/components/AddressFinder.vue";

export default {
    components: {
        AddressFinder,
    },
    data() {
        return {
            customer: {},
            customerId: null,
            formData: {
                first_name: null,
                last_name: null,
                organization_id: null,
                organization_name: null,
                pre_phone: null,
                phone: null,
                email: null,
                final_address: null,
                town: null,
                zip: null,
                country: null,
                lat: null,
                lon: null
            }
        }
    },
    created: function () {
        this.customerId = this.$route.params.id;
        this.loadData();
    },
    methods: {
        loadData() {
            this.$store.getters.api.get("/customer/" + this.customerId).then((response) => {
                this.customer = response.data.customer;
                // Prefill form
                this.formData.first_name = response.data.customer.first_name;
                this.formData.last_name = response.data.customer.last_name;
                this.formData.organization_id = response.data.customer.organization_id;
                this.formData.organization_name = response.data.customer.organization_name;
                this.formData.pre_phone = response.data.customer.pre_phone;
                this.formData.phone = response.data.customer.phone;
                this.formData.email = response.data.customer.email;
                this.formData.final_address = response.data.customer.address.full_address;
                this.formData.town = response.data.customer.address.town;
                this.formData.zip = response.data.customer.address.zip_code;
                this.formData.country = response.data.customer.address.country;
                this.formData.lat = response.data.customer.address.gps_lat;
                this.formData.lon = response.data.customer.address.gps_lon;
            });
        },
        submitForm() {
            this.$store.getters.api.put("/customer/" + this.customerId + "/edit", this.formData)
                .then((response) => {
                    this.formData = response.data;
                    this.$router.push({ "name": "customers" });
                })
                .catch((error) => {
                    console.error("Error updating customer:", error);
                });
        },
        addressData: function (lat, lon, address, zip, town, country) {
            this.formData.lat = lat;
            this.formData.lon = lon;
            this.formData.final_address = address;
            this.formData.zip = zip;
            this.formData.town = town;
            this.formData.country = country;
        }
    },
}
</script>
<style lang="">

</style>