<template>
    <div class="p-2 p-lg-5">
        <div class="row mb-5">
            <div class="col-6 ps-4 ms-2">
                <h2>{{ $t("titles.warehouses") }}</h2>
            </div>
            <div v-if="$store.getters.hasPerm('add_warehouse')" class="col-auto ms-auto">
                <router-link :to="{ name: 'AddWarehouse' }" class="button-top">{{ $t("warehouses.addWarehouse.label")
                }}</router-link>
            </div>
        </div>
        <Table endpoint="/warehouses" ref="table">
            <template v-slot:header>
                <div class="col-2 col-lg-1">{{ $t("warehouses.id") }}</div>
                <div class="col-4 col-lg-2">{{ $t("warehouses.name") }}</div>
                <div class="col-6 col-lg-5">{{ $t("warehouses.address") }}</div>
            </template>
            <template #row="line">
                <div class="col-2 col-lg-1">{{ line.item.id }}</div>
                <div class="col-4 col-lg-2">{{ line.item.name }}</div>
                <div class="col-6 col-lg-5">{{ line.item.address.full_address }}</div>
                <router-link :to="'/warehouse/' + line.item.id" class="col-3 col-lg-1 mt-4 mt-lg-0">{{ $t("warehouses.resources")
                }}</router-link>
                <router-link :to="'/warehouse/' + line.item.id + '/edit'" class="col-3 col-lg-1 mt-4 mt-lg-0">{{ $t("warehouses.edit")
                }}</router-link>
                <button @click="hideWarehouse(line.item.id)" class="col-3 col-lg-2 mt-4 mt-lg-0">{{ $t("warehouses.dontUse") }}</button>
            </template>
        </Table>
    </div>
</template>

<script>
import Table from "@/components/Table.vue";

export default {
    components: {
        Table
    },
    data() {
        return {

        }
    },
    methods: {
        hideWarehouse: function (id) {
            this.$store.getters.api.delete("/warehouse/" + id + "/hide").then((response) => {
                this.$refs.table.loadData();
            }).catch((response) => {
                alert(this.$t("error." + response?.response?.data?.error_code));
            });
        },
    },
}
</script>

<style lang="scss">

</style>