<template>
    <div class="p-2 p-lg-5">
        <div class="row mb-5">
            <div class="col-6 ps-4 ms-2">
                <h2>{{ $t("titles.users") }}</h2>
            </div>
            <div v-if="$store.getters.hasPerm('add_user')" class="col-auto ms-auto">
                <router-link :to="{ name: 'AddWarehouse' }" class="button-top">{{ $t("user.addUser.label")
                }}</router-link>
            </div>
        </div>
        <Table endpoint="/users" ref="table">
            <template v-slot:header>
                <div class="col-3 col-lg-2">{{ $t("user.username") }}</div>
                <div class="col-3 col-lg-2">{{ $t("user.firstName") }}</div>
                <div class="col-3 col-lg-2">{{ $t("user.lastName") }}</div>
                <div class="col-3 col-lg-2">{{ $t("user.functions") }}</div>
            </template>
            <template #row="line">
                <div class="col-3 col-lg-2">{{ line.item.username }}</div>
                <div class="col-3 col-lg-2">{{ line.item.first_name }}</div>
                <div class="col-3 col-lg-2">{{ line.item.last_name }}</div>
                <div class="col-3 col-lg-2">
                    <span v-if="line.item.functions.length >= 1"
                        v-for="(func, i) in line.item.functions" v-key="i">
                        <span v-if="i > 0">, </span>
                        {{ $t("functions."+func.name) }}
                    </span>
                    <span v-else>-</span>
                </div>
                <div class="col-3 col-lg-2 mt-4 mt-lg-0">
                    <router-link
                        :to="'/user/' + line.item.id"
                        class="button link-darker"
                        v-if="!isOwner(line.item.functions) && $store.getters.hasPerm('change_user')">
                        {{ $t("utils.edit") }}
                    </router-link>
                    <span v-else>-</span>
                </div>
                <div class="col-3 col-lg-2 mt-4 mt-lg-0">
                    <button
                        @click="deleteUser(line.item.id)"
                        class="button link-darker"
                        v-if="!isOwner(line.item.functions) && $store.getters.hasPerm('delete_user')">
                        {{ $t("utils.deactivate") }}
                    </button>
                    <span v-else>-</span>
                </div>
            </template>
        </Table>
    </div>
</template>

<script>
import Table from "@/components/Table.vue";

export default {
    components: {
        Table
    },
    data() {
        return {}
    },
    methods: {
        isOwner: function (functions) {
            var found = false;
            functions.forEach(element => {
                if (element.name == "owner") {
                    found = true;
                }
            });
            return found;
        },
        deleteUser: function (id) {
            this.$store.getters.api.delete("/user/" + id + "/delete").then((response) => {
                this.$refs.table.loadData();
            }).catch((response) => {
                alert(this.$t("error." + response?.response?.data?.error_code));
            });
        }
    }
}
</script>

<style lang="scss"></style>