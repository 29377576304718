<template>
    <div class="p-2 p-lg-5">
        <div class="col-12 px-2 px-lg-5">
            <div class="row">
                <div class="col-6">
                    <h2>{{ $t("titles.vacations") }}</h2>
                </div>
                <div class="col-auto ms-auto">
                    <router-link v-if="this.$store.getters.hasPerm('add_vacations')" :to="'/vacation/add'"
                        class="button-top">{{$t("vacations.add") }}
                    </router-link>
                </div>
            </div>
            <Table endpoint="/vacations" ref="table" class="mt-3">
                <template v-slot:header>
                    <div class="col-3 col-lg-3">{{ $t("vacations.warehouse") }}</div>
                    <div class="col-4 col-lg-2">{{ $t("vacations.from") }}</div>
                    <div class="col-2 col-lg-2">{{ $t("vacations.to") }}</div>
                </template>
                <template #row="line">
                    <div class="col-3 col-lg-3">{{ line.item.warehouse.name }}</div>
                    <div class="col-4 col-lg-2">{{ $d(line.item.vacation_from) }}</div>
                    <div class="col-2 col-lg-2">{{ $d(line.item.vacation_to) }}</div>
                    <div class="col-2 col-lg-3"></div>
                    <router-link v-if="this.$store.getters.hasPerm('change_vacations')" class="col-3 col-lg-1 mt-4 mt-lg-0"
                        :to="'/vacation/' + line.item.id + '/edit'">{{
                            $t("utils.edit")
                        }}</router-link>
                    <button v-if="this.$store.getters.hasPerm('delete_vacations')" @click="deleteVacation(line.item.id)"
                        class="button link-darker col-3 col-lg-1 mt-4 mt-lg-0">
                        {{ $t("utils.deactivate") }}
                    </button>
                </template>
            </Table>
        </div>
    </div>
</template>
<script>
import Table from "@/components/Table.vue";

export default {
    components: {
        Table
    },
    data() {
        return {
        }
    },
    methods: {
        deleteVacation: function (id) {
            this.$store.getters.api.delete("/vacation/" + id + "/delete").then((response) => {
                this.$refs.table.loadData();
            }).catch((response) => {
                alert(this.$t("error." + response?.response?.data?.error_code));
            });
        }
    },

}
</script>
<style scoped lang="scss">
</style>