<template>
    <div class="p-5">
        <h2>{{ $t("warehouses.addWarehouse.progress") }}</h2>
        <div class="row w-50" autocomplete="off">
            <div class="col-12">
                <label for="name" class="mt-2">{{ $t("warehouses.warehouseName") }}<span>*</span></label>
            </div>
            <div class="col-12">
                <input v-model="name" type="text" class="form-control" id="name" name="name" placeholder="Náš nový sklad"
                    required>
            </div>
            <div class="col-12 mt-4">
                <label for="address" class="mt-2">{{ $t("warehouses.findAddress") }}</label>
            </div>
            <div class="col-12">
                <AddressFinder :getData="addressData" />
            </div>
            <div class="col-6">
                <label for="address" class="mt-2">{{ $t("warehouses.address") }}<span>*</span></label>
            </div>
            <div class="col-6">
                <label for="town" class="mt-2">{{ $t("warehouses.town") }}<span>*</span></label>
            </div>
            <div class="col-6">
                <input v-model="address" type="text" class="form-control" id="address" name="address"
                    placeholder="Korunní 2569/108" required>
            </div>
            <div class="col-6">
                <input v-model="town" type="text" class="form-control" id="town" name="town" placeholder="Praha 10"
                    required>
            </div>
            <div class="col-3">
                <label for="zip" class="mt-2">{{ $t("warehouses.zip") }}<span>*</span></label>
            </div>
            <div class="col-9">
                <label for="country" class="mt-2">{{ $t("warehouses.state") }}<span>*</span></label>
            </div>
            <div class="col-3">
                <input v-model="zip" type="text" class="form-control" id="zip" name="zip" placeholder="11000" required>
            </div>
            <div class="col-9">
                <input v-model="country" type="text" class="form-control" id="country" name="country"
                    placeholder="Česká republika" required>
            </div>
            <div class="col-6">
                <label for="lon" class="mt-2">{{ $t("warehouses.lon") }}<span>*</span></label>
            </div>
            <div class="col-6">
                <label for="lat" class="mt-2">{{ $t("warehouses.lat") }}<span>*</span></label>
            </div>
            <div class="col-6">
                <input v-model="lat" type="text" class="form-control" id="lat" name="lat" placeholder="49,2131798130803"
                    required>
            </div>
            <div class="col-6">
                <input v-model="lon" type="text" class="form-control" id="lon" name="lon" placeholder="17,5175885924409"
                    required>
            </div>
            <AddDrivers :drivers="drivers" @drivers-selected="handleDriverSelected" />
            <AddWarehouseman :warehousemen="warehousemen" @warehousemen-selected="handleWarehousemanSelected" />
            <button @click="submitForm" type="submit" class="mt-4 btn btn-primary w-100 form-submit">
                {{ $t("warehouses.create") }}
            </button>
        </div>
    </div>
</template>
<script>
import AddDrivers from '../components/AddDrivers.vue';
import AddWarehouseman from '../components/AddWarehouseman.vue';
import AddressFinder from '../components/AddressFinder.vue';

export default {
    components: {
        AddWarehouseman,
        AddDrivers,
        AddressFinder
    },

    data() {
        return {
            name: "",
            drivers: [],
            warehousemen: [],
            lat: null,
            lot: null,
            address: null,
            zip: null,
            town: null,
            country: null,
            error: false
        };
    },
    created() {
        this.loadData();
    },
    computed: {
        formData() {
            return {
                name: this.name,
                address: this.address,
                town: this.town,
                zip: this.zip,
                country: this.country,
                lat: this.lat,
                lon: this.lon,
                drivers: [],
                warehousemen: []
            }
        }
    },
    methods: {
        loadData() {
            this.$store.getters.api.get("/drivers").then((response) => {
                this.drivers = response.data.items;
            });
            this.$store.getters.api.get("/warehousemen").then((response) => {
                this.warehousemen = response.data.items;
            });
        },
        handleDriverSelected(selectedDrivers) {
            this.formData.drivers = selectedDrivers
        },
        handleWarehousemanSelected(selectedWarehousemen) {
            this.formData.warehousemen = selectedWarehousemen
        },
        submitForm() {
            this.$store.getters.api.post("/warehouse/add", this.formData)
                .then((response) => {
                    this.formData = response.data;
                });
        },
        addressData: function (lat, lot, address, zip, town, country) {
            this.lat = lat;
            this.lot = lot;
            this.address = address;
            this.zip = zip;
            this.town = town;
            this.country = country;
        }
    }
}
</script>
<style lang="scss"></style>