<template>
    <div>
        <div class="month">
            <div class="row">
                <span class="col-8">
                    <span :style="{ fontSize: '18px' }">{{ months[currentMonth] }} {{ currentYear }}</span>
                </span>
                <span @click="previousMonth" class="col-2 previous-month no-select">&#10094;</span>
                <span @click="nextMonth" class="col-2 next-month no-select">&#10095;</span>
            </div>
        </div>

        <ul class="weekdays no-select">
            <li style="width: calc(100%/7)">Po</li>
            <li style="width: calc(100%/7)">Út</li>
            <li style="width: calc(100%/7)">St</li>
            <li style="width: calc(100%/7)">Čt</li>
            <li style="width: calc(100%/7)">Pá</li>
            <li style="width: calc(100%/7)">So</li>
            <li style="width: calc(100%/7)">Ne</li>
        </ul>
        <ul class="days">
            <li v-for="i in daysInPreviosMonth" style="width: calc(100%/7)" :key="i">
                <div class="not-in-month no-select">{{ i }}</div>
            </li>
            <li v-for="i in daysInMonth" style="width: calc(100%/7)" :key="i">
                <div class="no-select" :class="{ active: isSelected(i), today: isToday(i) }" @click="selectDate(i)">{{ i }}
                </div>
            </li>
            <li v-for="i in daysInNextMonth" style="width: calc(100%/7)" :key="i">
                <div class="not-in-month no-select">{{ i }}</div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: {
        selectedDate: {
            type: Date,
            required: false,
            default: new Date()
        },
    },
    data() {
        return {
            today: new Date(),
            selectedWeek: [],
            currentYear: null,
            currentMonth: null,
            updateTimeInterval: null,
            selectedOrder: null,
            showOneDay: false,
            showWithoutTravel: false,
            showIds: false,
            calendarFrom: 6,
            calendarTo: 18,
            months: [
                "Leden",
                "Únor",
                "Březen",
                "Duben",
                "Květen",
                "Červen",
                "Červenec",
                "Srpen",
                "Září",
                "Říjen",
                "Listopad",
                "Prosinec"
            ],
            dayShortName: [
                "Ne",
                "Po",
                "Út",
                "St",
                "Čt",
                "Pá",
                "So"
            ]
        }
    },
    created: function () {
        // if(this.selectedDate == null)
        // this.currentMonth = this.today.getMonth();
        // this.currentYear = this.today.getFullYear();

        // interval to update time
        this.updateTimeInterval = setInterval(function () {
            this.today = new Date();
        }.bind(this), 60000);

    },
    computed: {
        daysInMonth: function () {
            return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
        },
        daysInPreviosMonth: function () {
            var actualStart = new Date(this.currentYear, this.currentMonth, 1).getDay();
            // remove number of days
            if (actualStart == 0) {
                actualStart = 6
            }
            else {
                actualStart -= 1
            }
            var previousMonth = this.currentMonth - 1;
            var previousYear = this.currentYear;
            if (previousMonth <= -1) {
                previousMonth = 11;
                previousYear -= 1;
            }

            // create date list
            var dates = []
            var maxDates = new Date(previousYear, previousMonth + 1, 0).getDate();
            for (var i = maxDates - actualStart + 1; i <= maxDates; i++) {
                dates.push(i)
            }

            return dates
        },
        daysInNextMonth: function () {
            var actualEnd = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
            // remove number of days
            actualEnd = new Date(this.currentYear, this.currentMonth, actualEnd).getDay();
            if (actualEnd == 0) {
                actualEnd = 7;
            }
            // create date list
            var dates = []
            for (var i = 1; i <= 7 - actualEnd; i++) {
                dates.push(i)
            }

            return dates
        },
    },
    methods: {
        previousMonth: function () {
            this.currentMonth -= 1;
            if (this.currentMonth <= -1) {
                this.currentMonth = 11;
                this.currentYear -= 1;
            }
        },
        nextMonth: function () {
            this.currentMonth += 1;
            if (this.currentMonth >= 12) {
                this.currentMonth = 0;
                this.currentYear += 1;
            }
        },
        isSelected: function (i) {
            return i == this.selectedDate.getDate()
                && this.selectedDate.getMonth() == this.currentMonth
                && this.selectedDate.getFullYear() == this.currentYear;
        },
        isToday: function (i) {
            return !this.isSelected(i) && i == this.today.getDate()
                && this.today.getMonth() == this.currentMonth
                && this.today.getFullYear() == this.currentYear;
        },
        selectDate: function (i) {
            this.$emit('update:selectedDate', new Date(this.currentYear, this.currentMonth, i));
        }
    },
    watch: {
        selectedDate: function () {
            this.currentMonth = this.selectedDate.getMonth();
            this.currentYear = this.selectedDate.getFullYear();
        }
    }
}
</script>

<style scoped lang="scss"></style>