<template>
    <input type="text" class="form-control" ref="address" placeholder="Korunní 2569/108, Praha 10, 110 00">
</template>

<script>
export default {
    props: {
        getData: {
            type: Function
        }
    },
    data: function(){
        return {
            text: null,
            lat: null,
            lot: null,
            zip: null,
            town: null,
            country: null,
            address: null
        }
    },
    mounted: function(){
        var addressEl = this.$refs.address;
        if (addressEl) {
            let selected = {};

            var suggest = new SMap.Suggest(addressEl);
            suggest.addListener("suggest", function (suggestData) {
                var data = suggestData.data;

                this.lot = data.longitude;
                this.lat = data.latitude;

                var coords = SMap.Coords.fromWGS84(data.longitude, data.latitude);

                new SMap.Geocoder.Reverse(coords, this.reverseGeocoding);

            }.bind(this)).addListener("request-items", function (data) {
                // save user selected object from suggestions
                if (data.length) {
                    selected = data[0];
                }
                else {
                    selected = {};
                }
            }.bind(this)).addListener("close", function () {
                // select user selected object from suggestions
                var data = selected;
                this.lot = data.longitude;
                this.lat = data.latitude;
                this.sendData();
            }.bind(this))
        }
    },
    methods: {
        sendData: function () {
            this.getData(this.lat, this.lot, this.address, this.zip, this.town, this.country);
        },
        reverseGeocoding: function (geocoder) {
            var results = geocoder.getResults();
            var splited = results.label.split(",").splice(0,3)
            this.text = splited;
            if (splited.length >= 3 && splited[2].replaceAll(" ","").length == 5){
                this.zip = splited[2].replaceAll(" ","")
            }

            var items = results.items;
            for(var i = 0; i < items.length; i++){
                var item = items[i];
                if (item.type == "addr"){
                    this.address = item.name
                }
                if (item.type == "osmd"){
                    this.address = results.label
                }
                if (item.type == "muni" || item.type == "osmm"){
                    this.town = item.name
                }
                if (item.type == "coun" || item.type == "osmc"){
                    this.country = item.name
                }
            }
            this.sendData();
        }
    }
}
</script>