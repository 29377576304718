<template>
    <div v-if="Object.keys(order).length !== 0" class="row p-5" id="order">
        <div class="col-12 px-md-5">
            <div class="row">
                <div class="col-12 col-md-7">
                    <h2>{{ $t("orders.order") }} - {{ orderTitle }}</h2>
                </div>
                <div v-if="order.warehouse && order.currency" class="col-12 col-md-5 mt-3 mt-md-0 pl-md-5">
                    <button class="btn btn-primary w-50 form-submit" @click="sendForm()">{{ $t("addOrder.continue") }}</button>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12 col-md-7 pr-5">
                    <div class="row">
                        <div class="col-12">
                            <!-- Základní informace objednávce -->
                            <div class="row" autocomplete="off">
                                <div class="col-12">
                                    <h3>{{ $t("addOrder.orderInfo") }}</h3>
                                </div>
                                <div class="col-12">
                                    <label for="warehouse" class="mt-2">{{ $t("addOrder.pickWarehouse") }}</label>
                                    <select class="form-control form-select" v-model="order.warehouse_id" size="1">
                                        <option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">
                                            {{ warehouse.name }}, {{
                                                warehouse.address.full_address }} ({{ warehouse.distance }} km, {{
        warehouse.duration }}{{ $t("orders.minutes") }})</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <label for="warehouse" class="mt-2">{{
                                        $t("addOrder.pickCurrency") }}</label>
                                    <select v-model="order.currency" @change="changeCurrency"
                                        class="form-control form-select" id="currency" name="currency" size="1" required>
                                        <option value="CZK">CZK</option>
                                        <option value="EUR">EUR</option>
                                    </select>
                                </div>
                                <!-- MESSAGE -->
                                <div v-if="message" class="col-12">
                                    <div class="alert alert-danger mt-4 font-weight-bold" role="alert">
                                        {{ message }}
                                    </div>
                                </div>
                            </div>
                            <!-- Přidat položku -->

                            <div v-if="order.warehouse && order.currency" class="row"
                                autocomplete="off">
                                <div class="col-12 mt-4">
                                    <h3>{{ $t("addOrder.addItem") }}</h3>
                                </div>
                                <div v-for="productType  in available_products" :key="productType.name">
                                    <div class="col-12">
                                        <h4>{{ productType.name }}</h4>
                                        <label v-for="product in productType.products" :key="product.id" class="w-50">
                                            <input v-model="addItem.product" type="radio" name="product"
                                                class="card-input-element" :value="product" />
                                            <div class="panel panel-default card-input add-product m-3 font-weight-bold">
                                                <div class="panel-heading py-2 px-4">{{ product.name }}</div>
                                                <div class="panel-body py-2 px-4">
                                                    <span>{{ $t("products.length") }}: {{ product.length }}
                                                        cm</span><br>
                                                    <span>{{ $t("products.measureUnit") }}: {{ product.measurement_unit
                                                    }}</span><br>
                                                    <span v-for="price in filteredPrices(product)" :key="price.id">{{
                                                        $t("products.vatPrice") }}:
                                                        {{ formatNumber(price.amount, 2) }} {{ price.currency }}</span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label for="amount" class="mt-2">{{ $t("addOrder.itemsCount") }}</label>
                                </div>
                                <div class="col-12">
                                    <input v-model="addItem.amount" type="text" class="form-control" @keyup.enter="addItemToOrder()">
                                </div>
                                <div class="col-12">
                                    <button @click="addItemToOrder()" type="submit"
                                        class="mt-4 btn btn-primary w-100 form-submit">{{ $t("utils.add") }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="order.warehouse && order.currency" class="col-12 mt-4 mt-md-0 col-md-5 pl-md-5">
                    <div class="row">
                        <div class="col-12">
                            <div>{{ $t("products.noVatPrice") }}:</div>
                            <div><b>{{ formatNumber(priceWithoutVat, 2) }} {{ order.currency }}</b></div>
                            <div>{{ $t("products.vatPrice") }}:</div>
                            <div><b>{{ formatNumber(priceWithVat, 2) }} {{ order.currency }}</b></div>
                            <div method="post">
                                <div class="row align-items-center py-1">
                                    <div class="col-12">{{ $t("addOrder.distance") }}:</div>
                                    <div class="col-12 d-flex align-items-center">
                                        <input type="number" class="form-control w-50 font-weight-bold float-left"
                                            v-model="order.distance" step="1" >
                                        <span>&nbsp;km</span>
                                    </div>
                                    <div class="col-12">{{ $t("addOrder.duration") }}:</div>
                                    <div class="col-12 d-flex align-items-center">
                                        <input type="number" class="form-control w-50 font-weight-bold float-left"
                                            v-model="order.duration" step="1" >
                                        <span>&nbsp;min</span>
                                    </div>
                                    <div class="col-12">{{ $t("addOrder.transportPrice") }}:</div>
                                    <div class="col-12 d-flex align-items-center">
                                        <input type="number" class="form-control w-50 font-weight-bold float-left"
                                            v-model="order.delivery_price_amount"
                                            required>&nbsp;{{
                                                order.currency }}
                                    </div>
                                    <div class="col-12">{{ $t("addOrder.minimumTransportPrice") }}:</div>
                                    <div class="col-12 d-flex align-items-center">
                                        <input type="number" class="form-control w-50 font-weight-bold float-left"
                                            v-model="order.minimim_delivery_price"
                                            required>&nbsp;{{
                                                order.currency }}
                                    </div>
                                </div>
                            </div>
                            <!-- Položky objednávky -->

                            <h3 class="mt-4">{{ $t("addOrder.orderItems") }}</h3>
                            <div class="row">
                                <ItemBox v-if="order.products.length > 0" v-for="(item, index) in order.products"
                                    :key="index" :product="item"
                                    @delete="() => { deleteItem(index) }"/>
                                <p v-else class="col-12">{{ $t("orders.noItems") }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ItemBox from "@/components/ItemBox.vue";
export default {
    data() {
        return {
            order: {},
            available_products: {},
            warehouses: [],
            addItem: {
                product: {},
                amount: null
            },
            recomputeValue: false
        }
    },
    components: {
        ItemBox
    },
    created: function () {
        this.loadData();
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get('/order/' + this.$route.params.id).then(function (response) {
                this.order = response.data.item;
                if (this.order.warehouse) {
                    var distance = this.order.distance;
                    var duration = this.order.duration;
                    this.order.warehouse_id = this.order.warehouse.id;
                    setTimeout(function () { this.order.distance = distance; this.order.duration = duration; }.bind(this), 250);
                }
                if (this.order.delivery_price) {
                    setTimeout(function () {
                        this.order.delivery_price_amount = this.order.delivery_price.amount;
                        this.order.minimim_delivery_price = this.order.delivery_price.minimum_price;
                    }.bind(this), 250);
                }
                if (this.order.products) {
                    var products = this.order.products;
                    setTimeout(function () {
                        this.order.products = products;
                    }.bind(this), 250);
                }
            }.bind(this));
            this.updateProducts();
            this.$store.getters.api.get('/warehouse_distance/' + this.$route.params.id).then(function (response) {
                this.warehouses = response.data.warehouses_distance;
                if (this.order.warehouse_id == null) {
                    this.order.warehouse_id = this.warehouses[0].id;
                }
            }.bind(this));
        },
        updateProducts: function () {
            if (!this.order.warehouse || !this.order.currency) {
                return
            }
            const data = {
                params: {
                    warehouse_id: this.order.warehouse.id,
                    currency: this.order.currency
                }
            };
            this.available_product = [];
            this.$store.getters.api.get('/available_products', data).then(function (response) {
                this.available_products = response.data.products_types;
            }.bind(this));
        },
        deleteItem: function (index) {
            // Remove the item from the orderItems array
            this.order.products.splice(index, 1);
        },
        updateDistace: function () {
            if (!this.order.warehouse) {
                return
            }
            this.order.distance = this.order.warehouse.distance;
            this.order.duration = this.order.warehouse.duration;
        },
        addItemToOrder: function () {
            if (this.addItem.product && this.addItem.amount) {
                // Convert the amount to a number
                const amountToAdd = parseInt(this.addItem.amount);

                // Check if the product already exists in the orderItems list
                const existingItemIndex = this.order.products.findIndex(item => item.product.product.id == this.addItem.product.id);

                if (existingItemIndex !== -1) {
                    // If the product exists, update the quantity
                    this.order.products[existingItemIndex].amount += amountToAdd;
                } else {
                    // If the product doesn't exist, add a new item
                    var price = null;
                    var vat = null;
                    for (var i = 0; i < this.addItem.product.pricesWithVat.length; i++){
                        if (this.addItem.product.pricesWithVat[i].currency == this.order.currency) {
                            price = this.addItem.product.pricesWithVat[i].amount;
                        }
                    }
                    for (var i = 0; i < this.addItem.product.vats.length; i++){
                        if (this.addItem.product.vats[i].currency == this.order.currency) {
                            vat = this.addItem.product.vats[i].vat;
                        }
                    }
                    const newItem = {
                        product: {
                            product: this.addItem.product,
                            amount: price,
                            currency: this.order.currency,
                            vat: vat,
                            discount: 1
                        },
                        amount: amountToAdd
                    };
                    // Add the new item to the list
                    this.order.products.push(newItem);
                }

                // Clear the form fields after adding/updating the item
                this.addItem = { product: null, amount: '' };
            }
        },
        filteredPrices(product) {
            const selectedCurrency = this.order.currency;
            return product.pricesWithVat.filter(price => price.currency === selectedCurrency);
        },
        filteredItemPrices(item) {
            const selectedCurrency = this.order.currency;
            return item.product.pricesWithVat.filter(price => price.currency === selectedCurrency);
        },
        sendForm: function () {
            this.$store.getters.api.post("order/" + this.order.id + "/items", this.order).then(function (response) {
                this.$router.push('/order/' + this.$route.params.id + '/intern');
            }.bind(this));
        }
    },
    computed: {
        orderTitle: function () {
            if (this.order) {
                if (this.order.intern_id) {
                    return this.order.intern_id;
                }
                return this.order.id

            }
            return this.$route.params.id;
        },
        priceWithoutVat: function () {
            let totalPrice = 0;
            if (this.order.delivery_price_amount) {
                totalPrice = this.order.distance * 2 * this.order.delivery_price_amount;
                if (totalPrice < this.order.minimim_delivery_price) {
                    totalPrice = this.order.minimim_delivery_price;
                }
                if (this.order.currency == 'CZK') {
                    totalPrice /= 1.21;
                }
                else {
                    totalPrice /= 1.20;
                }
            }

            for (const item of this.order.products) {
                totalPrice += (item.amount * item.product.amount) / (1 + item.product.vat)
            }

            this.recomputeValue = false;

            if (!totalPrice) {
                return 0;
            }

            return totalPrice;
        },
        priceWithVat: function () {
            let totalPrice = 0;
            if (this.order.delivery_price_amount) {
                totalPrice = this.order.distance * 2 * this.order.delivery_price_amount;
                if (totalPrice < this.order.minimim_delivery_price) {
                    totalPrice = this.order.minimim_delivery_price;
                }
            }

            for (const item of this.order.products) {
                totalPrice += item.amount * item.product.amount
            }

            this.recomputeValue = false;

            if (!totalPrice) {
                return 0;
            }

            return totalPrice;
        }
    },

    watch: {
        'order.currency'() {
            this.updateProducts();
            if (this.order.currency == "CZK") {
                this.order.delivery_price_amount = 30;
                this.order.minimim_delivery_price = 600;
            }
            if (this.order.currency == "EUR") {
                this.order.delivery_price_amount = 1.25;
                this.order.minimim_delivery_price = 30;
            }
            this.order.products = [];
            this.recomputeValue = true;
        },
        'order.warehouse_id'() {
            for (var i = 0; i < this.warehouses.length; i++){
                if (this.warehouses[i].id == this.order.warehouse_id) {
                    this.order.warehouse = this.warehouses[i];
                    if (this.order.currency == "CZK") {
                        this.order.delivery_price_amount = 30;
                        this.order.minimim_delivery_price = 600;
                    }
                    if (this.order.currency == "EUR") {
                        this.order.delivery_price_amount = 1.25;
                        this.order.minimim_delivery_price = 30;
                    }
                }
            }
            this.updateProducts();
            this.updateDistace();
            this.recomputeValue = true;
        },
        order: {
            handler: function () {
                this.recomputeValue = true;
            },
            deep: true,
            immediate: true
        },
        'order.delivery_price_amount'() {
            var base = 0;
            var base_min = 0;
            if (this.order.currency == "EUR") {
                base = 1.25;
                base_min = 30;
            }
            if (this.order.currency == "CZK") {
                base = 30;
                base_min = 600;
            }
            if (this.order.delivery_price_amount != null && this.order.delivery_price_amount != base
                && this.order.minimim_delivery_price == base_min) {
                this.order.minimim_delivery_price = 0;
            }
            this.recomputeValue = true;
        },
        'order.products': {
            handler: function () {
                this.recomputeValue = true;
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/scss/abstracts/variables";

.panel {
    border: 2px solid $primary;
    border-radius: 25px;
    font-weight: bold;

    .panel-heading {
        border-bottom: 1px solid $primary;
    }
}

.card-input-element {
    display: none;
}

.add-product:hover {
    cursor: pointer;
    opacity: 0.9;
    border: 2px solid $brown;
}

.card-input-element:checked+.card-input,
.customer-selected {
    box-shadow: 0 0 5px 1px $brown;
    opacity: 1;
    border: 2px solid $brown;
}
</style>