<template>
    <div class="row">
        <div class="col-12 px-5">
            <div class="row">
                <div class="col-12  pl-5" id="order">
                    <div class="row">
                        <div class="col-12">
                            <h3>{{ $t("addOrder.findCustomer") }}</h3>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <label for="find_phone" class="mt-2">{{ $t("customer.phone") }}</label>
                                </div>
                                <div class="col-12">
                                    <input type="phone" class="form-control" placeholder="736 109 281" v-model="phone">
                                </div>
                                <div class="col-6">
                                    <label for="find_phone" class="mt-2">{{ $t("customer.firstName") }}</label>
                                </div>
                                <div class="col-6">
                                    <label for="find_phone" class="mt-2">{{ $t("customer.lastName") }}</label>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control" placeholder="Matěj" v-model="firstName">
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control" placeholder="Marek" v-model="lastName">
                                </div>
                            </div>
                            <div v-if="customers && customers.length > 1" class="row mt-3">
                                <div class="col-12 mt-3">
                                    <h3>{{ $t("addOrder.possibleCustomers") }}</h3>
                                </div>
                                <div class="col-12" v-for="customer in customers" :key="customer.id">
                                    <div class="row customer-card p-2 m-2" @click="selectCustomer(customer)"
                                        style="font-size: 14px;"
                                        :class="{ 'customer-selected': selectedCustomer && customer.id === selectedCustomer.id }">
                                        <div class="col-12">{{ $t("customer.internId") }} <span><b>{{ customer.intern_id }}</b></span>
                                        </div>
                                        <div class="col-12">{{ $t("customer.firstName") }}: <span><b>{{ customer.first_name }} {{ customer.last_name
                                        }}</b></span></div>
                                        <div class="col-12">{{ $t("customer.email") }}: <span><b>{{ customer.email }}</b></span></div>
                                        <div class="col-12">{{ $t("customer.phone") }}: <span><b>{{ customer.phone }}</b></span></div>
                                        <div class="col-12">{{ $t("customer.address") }}: <span><b>{{ customer.address }}</b></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4" v-if="selectedCustomer">
                                <div class="col-12">
                                    <h3>{{ $t("addOrder.selectedCustomer") }}</h3>
                                </div>
                                <div class="col-12">{{ $t("customer.internId") }}: <span><b>{{ selectedCustomer.intern_id }}</b></span>
                                </div>
                                <div class="col-12">{{ $t("customer.firstName") }}: <span><b>{{ selectedCustomer.first_name }} {{
                                    selectedCustomer.last_name }}</b></span></div>
                                <div class="col-12">{{ $t("customer.email") }}: <span><b>{{ selectedCustomer.email }}</b></span></div>
                                <div class="col-12">{{ $t("customer.address") }}: <span><b>{{ selectedCustomer.address }}</b></span></div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mt-5">
                                <button @click="submitForm()" class="mt-4 btn btn-primary w-100 form-submit"
                                    :class="{ disabled: !selectedCustomer }" :disabled='!selectedCustomer'>{{ $t("addOrder.add") }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            selectedCustomer: null,
            customers: [],
            searching: false,
            phone: null,
            firstName: null,
            lastName: null
        };
    },
    computed: {
        selectedCustomerId: function () {
            if (this.selectedCustomer) {
                return this.selectedCustomer.id;
            }
            return "";
        }
    },
    watch: {
        phone: function (new_v) {
            if (new_v) {
                this.getCustomer();
            } else {
                this.customers = [];
                this.selectedCustomer = null;
            }
        },
        lastName: function (new_v) {
            if (new_v) {
                this.getCustomer();
            } else {
                this.customers = [];
                this.selectedCustomer = null;
            }
        },
        firstName: function (new_v) {
            if (new_v) {
                this.getCustomer();
            } else {
                this.customers = [];
                this.selectedCustomer = null;
            }
        }
    },
    methods: {
        getCustomer: function () {
            var that = this;
            this.$store.getters.api.get('/api/get-customers/phone', { params: { phone: this.phone, first_name: this.firstName, last_name: this.lastName } }).then(function (response) {
                that.customers = response.data.data;
                if (that.customers &&that.customers.length == 1) {
                    that.selectedCustomer = that.customers[0];
                }
                if (that.customers &&that.customers.length == 0) {
                    that.selectedCustomer = null;
                }
            });
        },
        selectCustomer: function (customer) {
            this.selectedCustomer = customer;
        },
        submitForm() {
            this.$store.getters.api.post('/order/' + this.$route.params.id + '/customer', {id_found: this.selectedCustomer.id})
                .then(function (response) {
                    this.$router.push('/order/' + this.$route.params.id + '/items')
                }.bind(this));
        },

    }
};
</script>
<style lang="">

</style>