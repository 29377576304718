<template>
    <div class="row px-5">
        <div class="col-12">
            <h2>{{ $t("orders.order") }} - {{ $route.params.id }}</h2>
        </div>
        <div class="col-12 col-md-6 mb-5">
            <AddCustomerForm :redirectTo="'/order/' + $route.params.id + '/items'" :endpoint="'/order/' + $route.params.id + '/customer'" />
        </div>
        <div class="col-12 col-md-6">
            <FindCustomer />
        </div>

    </div>
</template>
<script>
import AddCustomerForm from "@/components/AddCustomerForm.vue";
import FindCustomer from "@/components/FindCustomer.vue";
export default {
    components: {
        AddCustomerForm,
        FindCustomer
    },

}
</script>
<style lang="">

</style>