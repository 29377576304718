import { createRouter, createWebHistory } from 'vue-router';

import store from "@/store.js"

import Login from '@/views/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
import Profile from '@/views/Profile.vue';
import Users from '@/views/Users.vue';
import User from '@/views/User.vue';
import Warehouses from '@/views/Warehouses.vue';
import Customers from '@/views/Customers.vue';
import AddWarehouse from '@/views/AddWarehouse.vue';
import WarehouseStock from '@/views/WarehouseStock.vue';
import EditWarehouse from '@/views/EditWarehouse.vue';
import AddCustomer from '@/views/AddCustomer.vue';
import Customer from '@/views/Customer.vue';
import Products from '@/views/Products.vue';
import AddProduct from '@/views/AddProduct.vue';
import ChangeProductPrice from '@/views/ChangeProductPrice.vue';
import Vacations from '@/views/Vacations.vue';
import AddVacation from '@/views/AddVacation.vue';
import EditVacation from '@/views/EditVacation.vue';
import Overviews from '@/views/Overviews.vue';
import Orders from '@/views/Orders.vue';
import OrderDetail from '@/views/OrderDetail.vue';
import UpdateOrderCustomer from '@/views/UpdateOrderCustomer.vue';
import UpdateOrderItems from '@/views/UpdateOrderItems.vue';
import UpdateOrderIntern from '@/views/UpdateOrderIntern.vue';
import EditCustomer from '@/views/EditCustomer.vue';
import UpdateStock from '@/views/UpdateStock.vue';

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            title: "titles.login",
            navbar: false,
        }
    },
    {
        path: '/logout/',
        name: 'logout',
        async beforeEnter(to, from, next) {
            // after logout redirect to login
            await store.getters.api.get("/auth/logout");
            store.commit("saveUserProfile", { permissions: [] });
            next({ name: "login" });
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: "titles.dashboard",
            navbar: true,
        }
    },
    {
        path: '/account',
        name: 'account',
        component: Profile,
        meta: {
            title: "titles.account",
            navbar: true,
        }
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
            title: "titles.users",
            navbar: true,
        }
    },
    {
        path: '/user/:id',
        name: 'user',
        component: User,
        meta: {
            title: "titles.user",
            navbar: true,
        }
    },
    {
        path: '/warehouses',
        name: 'warehouses',
        component: Warehouses,
        meta: {
            title: "titles.warehouses",
            navbar: true,
        }
    },
    {
        path: '/customers',
        name: 'customers',
        component: Customers,
        meta: {
            title: "titles.customers",
            navbar: true,
        }
    },
    {
        path: '/customer/add',
        name: 'AddCustomer',
        component: AddCustomer,
        meta: {
            title: "titles.customers",
            navbar: true,
        }
    },
    {
        path: '/warehouses/add',
        name: 'AddWarehouse',
        component: AddWarehouse,
        meta: {
            title: "titles.addWarehouse",
            navbar: true,
        }
    },
    {
        path: '/warehouse/:id',
        name: 'WarehouseStock',
        component: WarehouseStock,
        meta: {
            title: "titles.warehouse",
            navbar: true,
        }
    },
    {
        path: '/warehouse/:id/edit',
        name: 'EditWarehouse',
        component: EditWarehouse,
        meta: {
            title: "titles.updateWarehouse",
            navbar: true,
        }
    },
    {
        path: '/update-stock/:warehouse_id/:product_id',
        name: 'UpdateStock',
        component: UpdateStock,
        meta: {
            title: "titles.warehouseStock",
            navbar: true,
        }
    },
    {
        path: '/customer/:id',
        name: 'Customer',
        component: Customer,
        meta: {
            title: "titles.customer",
            navbar: true,
        }
    },
    {
        path: '/customer/:id/edit',
        name: 'EditCustomer',
        component: EditCustomer,
        meta: {
            title: "titles.updateCustomer",
            navbar: true,
        }
    },
    {
        path: '/products',
        name: 'Products',
        component: Products,
        meta: {
            title: "titles.products",
            navbar: true,
        }
    },
    {
        path: '/product/add',
        name: 'AddProduct',
        component: AddProduct,
        meta: {
            title: "titles.addProduct",
            navbar: true,
        }
    },
    {
        path: '/product/change-price/:id',
        name: 'ChangeProductPrice',
        component: ChangeProductPrice,
        meta: {
            title: "titles.changePrice",
            navbar: true,
        }
    },
    {
        path: '/vacations',
        name: 'Vacations',
        component: Vacations,
        meta: {
            title: "titles.vacations",
            navbar: true,
        }
    },
    {
        path: '/vacation/add',
        name: 'AddVacation',
        component: AddVacation,
        meta: {
            title: "titles.addVacation",
            navbar: true,
        }
    },
    {
        path: '/vacation/:id/edit',
        name: 'EditVacation',
        component: EditVacation,
        meta: {
            title: "titles.editVacation",
            navbar: true,
        }
    },
    {
        path: '/overviews',
        name: 'Overviews',
        component: Overviews,
        meta: {
            title: "titles.overviews",
            navbar: true,
        }
    },
    {
        path: '/orders',
        name: 'Orders',
        component: Orders,
        meta: {
            title: "titles.orders",
            navbar: true,
        }
    },
    {
        path: '/order/:id',
        name: 'OrderDetail',
        component: OrderDetail,
        meta: {
            title: "titles.orderDetail",
            navbar: true,
        }
    },
    {
        path: '/order/:id/customer',
        name: 'UpdateOrderCustomer',
        component: UpdateOrderCustomer,
        meta: {
            title: "titles.updateOrderCustomer",
            navbar: true,
        }
    },
    {
        path: '/order/:id/items',
        name: 'UpdateOrderItems',
        component: UpdateOrderItems,
        meta: {
            title: "titles.updateOrderItems",
            navbar: true,
        }
    },
    {
        path: '/order/:id/intern',
        name: 'UpdateOrderIntern',
        component: UpdateOrderIntern,
        meta: {
            title: "titles.updateOrderIntern",
            navbar: true,
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

router.beforeEach((to, from, next) => {
    const noLoginPaths = ['login', 'logout',]

    if (store.getters.isLoggedIn || noLoginPaths.includes(to.name)) {
        next();
    } else {
        next({ name: 'login', query: { path: to.path } });
    }
})

export default router;